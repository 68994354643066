import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { SELECTORS } from '@oneaudi/stck-store';
import { FilterOverlayContainer, Container } from '../styles/FilterOverlay.styles';
import FilterOverlayFooter from './FilterOverlayFooter';
import FilterChips from './FilterChips';
import FilterOverlayBody from './FilterOverlayBody';
import FilterOverlayNavigation from './FilterOverlayNavigation';
import { SECTION, OVERLAY_ANCHOR_DESKTOP, TARGET_DESKTOP } from './Filter';
import { getContentSectionTopPadding } from '../utils/utils';
import { useServicesContext } from '../../../context';
import { useDesktopOrMobileView } from '../../../hooks';
import { CheckboxFilter } from './CheckboxFilter';
import { ModelFilter } from './ModelFilter';
import { RangeFilter } from './RangeFilter';
import { trackClicks } from '../tracking';
import { useClearAllFilters } from '../FilterContextHooks';
import LocationFilter from './LocationFilter';
import { FilterContext } from '../FilterContext';
export const FilterOverlay = ({ configuredFilters, formattedCheckboxFilterDataFromSCS, isFilterOverlayOpened, modelFilterData, newPickedContentSection, equipmentFilterCategories, closeOverlay, }) => {
    const clearAllFilters = useClearAllFilters();
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    let { lightMode } = audiContentService.getContent().fields;
    const context = React.useContext(FilterContext);
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const overlayRef = useRef(null);
    const sectionsRef = useRef([]);
    const [overlayNavBarState, setOverlayNavBarState] = useState(`${SECTION}1${OVERLAY_ANCHOR_DESKTOP}`);
    const [openedChipsContainer, setOpenedChipsContainer] = useState(false);
    const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window === null || window === void 0 ? void 0 : window.innerWidth : 320);
    const isDesktopOrTablet = useDesktopOrMobileView('768px') === 'desktop';
    const prevOpenedChipsContainer = useRef(null);
    useSelector((state) => {
        const allActivePresetsAndFilters = SELECTORS.FILTERS.getSelectedFiltersParamArray(state);
        if (allActivePresetsAndFilters.length > 0 &&
            allActivePresetsAndFilters.length !== prevOpenedChipsContainer.current) {
            setOpenedChipsContainer(true);
            prevOpenedChipsContainer.current = allActivePresetsAndFilters.length;
        }
        else if (allActivePresetsAndFilters.length === 0 &&
            allActivePresetsAndFilters.length !== prevOpenedChipsContainer.current &&
            prevOpenedChipsContainer.current !== null) {
            setOpenedChipsContainer(false);
            prevOpenedChipsContainer.current = allActivePresetsAndFilters.length;
        }
    });
    const navigateToContentSection = (id, anchorTrack) => {
        var _a, _b;
        const navigateToId = (id === null || id === void 0 ? void 0 : id.includes(`${OVERLAY_ANCHOR_DESKTOP}`))
            ? id.replace(`${OVERLAY_ANCHOR_DESKTOP}`, `${TARGET_DESKTOP}`)
            : `${id}${TARGET_DESKTOP}`;
        const foundContentSection = sectionsRef.current.find((elem) => (elem === null || elem === void 0 ? void 0 : elem.id) === navigateToId);
        const elementToSetFocusOn = document.getElementById(navigateToId.replace(`${TARGET_DESKTOP}`, 'FocusElement'));
        if (typeof anchorTrack === 'undefined' && windowWidth >= 768) {
            const trackingEventName = 'vtp filter - click on category - anchor link';
            trackClicks(trackingService, trackingEventName, ((_a = foundContentSection === null || foundContentSection === void 0 ? void 0 : foundContentSection.firstElementChild) === null || _a === void 0 ? void 0 : _a.getAttribute('data-trackid')) || '', 'text link', ((_b = foundContentSection === null || foundContentSection === void 0 ? void 0 : foundContentSection.firstElementChild) === null || _b === void 0 ? void 0 : _b.getAttribute('data-trackid')) || '', false);
        }
        if (foundContentSection && overlayRef.current) {
            elementToSetFocusOn === null || elementToSetFocusOn === void 0 ? void 0 : elementToSetFocusOn.focus();
            const contentSectionPaddingTop = getContentSectionTopPadding(windowWidth);
            overlayRef.current.scrollBy({
                top: foundContentSection.getBoundingClientRect().top - contentSectionPaddingTop,
                left: 0,
            });
        }
    };
    const renderFilters = (configuredCategory, visibleIn, headingLevel) => {
        return configuredCategory.filterGroups.map((filterGroup) => {
            if (filterGroup.filterType === 'checkbox' ||
                filterGroup.filterType === 'car-type-icon-checkbox' ||
                filterGroup.filterType === 'color-checkbox') {
                const checkboxGroup = formattedCheckboxFilterDataFromSCS === null || formattedCheckboxFilterDataFromSCS === void 0 ? void 0 : formattedCheckboxFilterDataFromSCS.find((element) => element.groupHeadline === filterGroup.filter);
                if (checkboxGroup) {
                    return (React.createElement(CheckboxFilter, { visibleIn: visibleIn, key: `${checkboxGroup.groupHeadline}-checkboxfiltergroupheadline`, filterCategory: configuredCategory.filterCategory, group: checkboxGroup, isCarTypeIconCheckbox: filterGroup.filterType === 'car-type-icon-checkbox', isColorTypeCheckbox: filterGroup.filterType === 'color-checkbox', isPartOfEquipmentFilter: !!filterGroup.isPartOfEquipmentFilter, layoutWidth: filterGroup.layoutWidth, headingLevel: headingLevel }));
                }
                console.error(`No SCS data for configured ${filterGroup.filter} filter`);
            }
            if (filterGroup.filterType === 'range') {
                return (React.createElement(RangeFilter, { visibleIn: visibleIn, filterCategory: configuredCategory.filterCategory, filterGroup: filterGroup.filter, key: filterGroup.filter, layoutWidth: filterGroup.layoutWidth }));
            }
            if (filterGroup.filterType === 'model-checkbox') {
                const checkboxGroup = formattedCheckboxFilterDataFromSCS === null || formattedCheckboxFilterDataFromSCS === void 0 ? void 0 : formattedCheckboxFilterDataFromSCS.find((element) => element.groupHeadline === filterGroup.filter);
                return (React.createElement(ModelFilter, { visibleIn: visibleIn, group: checkboxGroup, key: filterGroup.filter, filterCategory: configuredCategory.filterCategory, isFilterOverlayOpened: isFilterOverlayOpened, layoutWidth: filterGroup.layoutWidth, modelFilterData: modelFilterData }));
            }
            if (filterGroup.filterType === 'location') {
                return (React.createElement(LocationFilter, { key: filterGroup.filter, visibleIn: visibleIn, overlayRef: overlayRef }));
            }
            return null;
        });
    };
    const handleKeyDown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 'f') {
            const trackingEventName = 'vtp filter - open search with STRG/CMD + F';
            trackClicks(trackingService, trackingEventName, '', '', '', true);
        }
    };
    const handleResize = () => {
        setWindowWidth(typeof window !== 'undefined' ? window === null || window === void 0 ? void 0 : window.innerWidth : 320);
    };
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window === null || window === void 0 ? void 0 : window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            if (typeof window !== 'undefined') {
                window === null || window === void 0 ? void 0 : window.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, []);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window === null || window === void 0 ? void 0 : window.addEventListener('resize', handleResize);
        }
        return () => {
            if (typeof window !== 'undefined') {
                window === null || window === void 0 ? void 0 : window.removeEventListener('resize', handleResize);
            }
        };
    }, [windowWidth]);
    useEffect(() => {
        if (isFilterOverlayOpened) {
            setTimeout(() => {
                navigateToContentSection(newPickedContentSection, false);
            }, 500);
        }
    }, [newPickedContentSection, isFilterOverlayOpened]);
    return (React.createElement(FilterOverlayContainer, { ref: overlayRef, "data-ref": "filter_overlay_container_ref", "aria-modal": "true", role: "dialog", lightMode: lightMode },
        React.createElement(Container, null,
            React.createElement(FilterOverlayNavigation, { configuredFilters: configuredFilters, isFilterOverlayOpened: isFilterOverlayOpened, overlayNavBarState: overlayNavBarState, equipmentFilterCategories: equipmentFilterCategories, clearAllFilters: clearAllFilters, closeOverlay: closeOverlay, navigateToContentSection: navigateToContentSection, renderFilters: renderFilters, overlayRef: overlayRef }),
            isDesktopOrTablet && (React.createElement(FilterOverlayBody, { configuredFilters: configuredFilters, openedChipsContainer: openedChipsContainer, overlayRef: overlayRef, sectionsRef: sectionsRef, renderFilters: renderFilters, setOverlayNavBarState: setOverlayNavBarState, equipmentFilterCategories: equipmentFilterCategories, closeOverlay: closeOverlay, clearAllFilters: clearAllFilters })),
            openedChipsContainer && React.createElement(FilterChips, { configuredFilters: configuredFilters })),
        React.createElement(FilterOverlayFooter, { clearAllFilters: clearAllFilters, closeOverlay: closeOverlay })));
};
export default FilterOverlay;
