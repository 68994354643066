var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const DEFAULT_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
export function httpRequest(requestUrl, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(requestUrl, Object.assign({ headers: DEFAULT_HEADERS }, options));
        response.parsedBody = yield response.json().catch((error) => {
            throw error;
        });
        if (!response.ok) {
            throw new HttpRequestError(`Error fetching ${requestUrl}: ${response.statusText}`, response.status, response.statusText);
        }
        return response;
    });
}
export class HttpRequestError extends Error {
    constructor(msg, _statusCode, _statusText) {
        super(msg);
        this._statusCode = _statusCode;
        this._statusText = _statusText;
        Object.setPrototypeOf(this, HttpRequestError.prototype);
    }
    get statusCode() {
        return this._statusCode;
    }
    get statusText() {
        return this._statusText;
    }
}
