import { MarketType } from './seo.types';
export const messagesForMarket = (marketType, filters) => {
    return {
        titleCarline: {
            id: 'nemo.sc.meta.title.carline',
            defaultMessage: 'Audi {{carline}}',
            placeholder: { carline: filters.carline },
        },
        titleDealerName: {
            id: `nemo.sc.meta.title.${marketType.toLocaleLowerCase()}.dealerName`,
            defaultMessage: '{{dealerName}}',
            placeholder: { dealerName: filters.dealer },
        },
        titleDrive: {
            id: 'nemo.sc.meta.title.drive',
            defaultMessage: '{{drive}}',
            placeholder: { drive: filters.drive },
        },
        titleExteriorColor: {
            id: 'nemo.sc.meta.title.exterior-color',
            defaultMessage: '{{exteriorColor}}',
            placeholder: { exteriorColor: filters.exteriorColor },
        },
        titleFuelType: {
            id: 'nemo.sc.meta.title.fuel-type',
            defaultMessage: '{{fuelType}}',
            placeholder: { fuelType: filters.fuelType },
        },
        titleGearType: {
            id: 'nemo.sc.meta.title.gear-type',
            defaultMessage: '{{gearType}}',
            placeholder: { gearType: filters.gearType },
        },
        titleSuffix1: {
            id: `nemo.sc.meta.title.${marketType.toLocaleLowerCase()}.suffix.1`,
            defaultMessage: marketType === MarketType.NC ? 'Neu' : 'Gebraucht',
        },
        titleSuffix2: {
            id: `nemo.sc.meta.title.${marketType.toLocaleLowerCase()}.suffix.2`,
            defaultMessage: marketType === MarketType.NC ? 'Neuwagen' : 'Gebrauchtwagen',
        },
        titleSuffix3: {
            id: `nemo.sc.meta.title.${marketType.toLocaleLowerCase()}.suffix.3`,
            defaultMessage: marketType === MarketType.NC
                ? ': Schnell verfügbare Neuwagen'
                : 'Gebrauchtwagen mit Garantie',
        },
        titleSuffix4: {
            id: `nemo.sc.meta.title.${marketType.toLocaleLowerCase()}.suffix.4`,
            defaultMessage: marketType === MarketType.NC
                ? ': Schnell verfügbare Neuwagen mit attraktiven Angeboten'
                : 'Gebrauchtwagen mit plus: Garantie',
        },
        descCarline: {
            id: 'nemo.sc.meta.desc.carline',
            defaultMessage: 'Audi {{carline}}',
            placeholder: { carline: filters.carline },
        },
        descDealerName: {
            id: `nemo.sc.meta.desc.${marketType.toLocaleLowerCase()}.dealerName`,
            defaultMessage: '{{dealerName}}',
            placeholder: { dealerName: filters.dealer },
        },
        descDrive: {
            id: 'nemo.sc.meta.desc.drive',
            defaultMessage: ', {{drive}}',
            placeholder: { drive: filters.drive },
        },
        descExteriorColor: {
            id: 'nemo.sc.meta.desc.exterior-color',
            defaultMessage: 'in {{exteriorColor}}',
            placeholder: { exteriorColor: filters.exteriorColor },
        },
        descFuelType: {
            id: 'nemo.sc.meta.desc.fuel-type',
            defaultMessage: 'mit {{fuelType}}-Motor',
            placeholder: { fuelType: filters.fuelType },
        },
        descGearType: {
            id: 'nemo.sc.meta.desc.gear-type',
            defaultMessage: ', {{gearType}}',
            placeholder: { gearType: filters.gearType },
        },
        descSuffix1: {
            id: `nemo.sc.meta.desc.${marketType.toLocaleLowerCase()}.suffix.1`,
            defaultMessage: marketType === MarketType.NC ? 'Audi Neuwagen' : 'Geprüfte Gebrauchtwagen',
        },
        descSuffix2: {
            id: `nemo.sc.meta.desc.${marketType.toLocaleLowerCase()}.suffix.2`,
            defaultMessage: marketType === MarketType.NC
                ? 'Große Auswahl an Audi Neuwagen.'
                : 'Geprüfte Gebrauchtwagen und Jahreswagen',
        },
        descSuffix3: {
            id: `nemo.sc.meta.desc.${marketType.toLocaleLowerCase()}.suffix.3`,
            defaultMessage: marketType === MarketType.NC
                ? 'Große Auswahl an Neuwagen von Audi Händlern.'
                : 'Geprüfte Gebrauchtwagen und Jahreswagen von Audi :plus Händlern',
        },
        descSuffix4: {
            id: `nemo.sc.meta.desc.${marketType.toLocaleLowerCase()}.suffix.4`,
            defaultMessage: marketType === MarketType.NC
                ? 'Große Auswahl an Neuwagen direkt vom Audi Händler in Ihrer Nähe.'
                : 'Geprüfte Gebrauchtwagen und Jahreswagen von Audi :plus Händlern aus ganz Deutschland',
        },
        descSuffix5: {
            id: `nemo.sc.meta.desc.${marketType.toLocaleLowerCase()}.suffix.5`,
            defaultMessage: marketType === MarketType.NC
                ? 'Große Auswahl an Neuwagen direkt vom Audi Händler in Ihrer Nähe. Jetzt leasen oder finanzieren.'
                : 'Geprüfte Gebrauchtwagen und Jahreswagen von Audi :plus Händlern aus ganz Deutschland Finanzierung und Leasing möglich.',
        },
        descSuffix6: {
            id: `nemo.sc.meta.desc.${marketType.toLocaleLowerCase()}.suffix.6`,
            defaultMessage: marketType === MarketType.NC
                ? 'Große Auswahl an Neuwagen direkt vom Audi Händler in Ihrer Nähe. Jetzt zu attraktiven Konditionen leasen oder finanzieren.'
                : 'Geprüfte Gebrauchtwagen und Jahreswagen von Audi :plus Händlern aus ganz Deutschland Finanzierung und Leasing sowie Online-Kauf möglich.',
        },
    };
};
