import styled from 'styled-components';
import React from 'react';
import { Checkbox } from '@audi/audi-ui-react-v2';
export const StyledColorNameLabel = styled.p `
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  display: inline;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) and (max-width: calc(${(props) => props.theme.breakpoints.l}px - 1px)) {
    display: block;
  }
`;
export const StyledCountLabel = styled(StyledColorNameLabel) ``;
export const StyledCheckboxColorTile = styled((props) => React.createElement(Checkbox, Object.assign({}, props))) `
  grid-template-columns: 48px auto;
  grid-template-rows: 48px auto;
  grid-template-areas: 'colortile label';
  align-items: center;
  justify-items: start;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    grid-template-columns: ${(props) => (props.isPlpQuickFilters ? 'unset' : '88px')};
    grid-template-rows: ${(props) => (props.isPlpQuickFilters ? 'unset' : '88px auto')};
    grid-template-areas: 'colortile' 'label';
    grid-row-gap: var
      ${(props) => (props.isPlpQuickFilters ? 'unset' : props.theme.responsive.spacing.s)};
    align-items: start;
  }

  input {
    background-image: url(${(props) => props.colorUrl});
    background-position: center;
    height: 40px !important;
    width: 40px;
    margin: 4px;
    height: ${(props) => (props.isPlpQuickFilters ? '42px !important' : '40px !important')};
    width: ${(props) => (props.isPlpQuickFilters ? '42px' : '40px')};
    grid-area: colortile;

    overflow: visible;
    position: relative;

    mask: none;
    border: none;
    border-radius: ${(props) => (props.isPlpQuickFilters ? '50px' : '0')};

    @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
      height: ${(props) => props.isPlpQuickFilters ? '42px !important' : '80px !important'};
      width: ${(props) => (props.isPlpQuickFilters ? '42px' : '80px')};
    }

    &::before {
      transform: none;
      border: 1px solid transparent;
      height: ${(props) => (props.isPlpQuickFilters ? '46px' : '44px')};
      width: ${(props) => (props.isPlpQuickFilters ? '46px' : '44px')};
      margin: 1px;
      top: -4px;
      left: -4px;
      mask: none;
      background-color: transparent;

      @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
        height: ${(props) => (props.isPlpQuickFilters ? '46px' : '88px')};
        width: ${(props) => (props.isPlpQuickFilters ? '46px' : '88px')};
        margin: 3px;
        top: ${(props) => (props.isPlpQuickFilters ? '-6px' : '-8px')};
        left: ${(props) => (props.isPlpQuickFilters ? '-6px' : '-8px')};
      }
    }
    &:disabled::before {
      background-color: transparent;
    }
    &:checked::before {
      background-color: transparent;
      border: 1px solid var(${(props) => props.theme.colors.interaction.secondary.hover});
      border-radius: inherit;
    }
    &:disabled {
      opacity: ${(props) => (props.isPlpQuickFilters ? '0.6' : '1')};
    }
  }

  input + div {
    grid-area: label;
  }

  label {
    @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
      margin-inline-start: 4px;
    }
  }
`;
