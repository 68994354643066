var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { LocationFilterBodyContainer } from '../styles/LocationFilter.styles';
import { Loader, NavigationBar, NavigationItem } from '@audi/audi-ui-react-v2';
import { Status } from '@googlemaps/react-wrapper';
import { doDealerDataDiffer, removeRadiusMarker, setRadiusMarker, createDealersData, shouldBeUsedFilterDataWithoutClearingDealers, initializeMap, initializeAutocomplete, updateMarkerIcons, } from '../utils/locationFilterHelpers';
import { getDealers } from '../filterService';
import { useServicesContext } from '../../../context';
import { useDesktopOrMobileView, getScsUrlPartsFromConfiguration, useClientServerUtils, } from '../../../hooks';
import { useFilterData, useClearAllDealerFilters, useApplyFilters } from '../FilterContextHooks';
import { DealersListWrapper } from './DealersListWrapper';
import { useMarkersAndDealersService } from '../hooks/locationFilterHooks';
import { MapWrapper } from './MapWrapper';
import MapBlockedWrapper from './MapBlockedWrapper';
import { SelectAllDealersCheckbox } from './SelectAllDealersCheckbox';
import { useI18n } from '@oneaudi/i18n-context';
import { FilterContext } from '../FilterContext';
import { ExpiringStorage } from '@oneaudi/stck-store';
export const LocationFilterBody = ({ changedByLocateMe, consent, inputRef, map, visibleIn, mapConsentButtonText, openConsentOverlay, setIsTwoClickOverlayOpened, setMap, }) => {
    var _a, _b, _c;
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    const context = React.useContext(FilterContext);
    const { wholeMarketDealersFetch, setWholeMarketDealersFetch, radius, setRadius, prevSearchedCoords, setPrevSearchedCoords, searchedCoords, setSearchedCoords, } = context;
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const country = localeService.countryCode;
    const countryISO = country.toUpperCase();
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    const { locationFilterConfig_defaultRadius } = audiContentService.getContent()
        .fields;
    const defaultRadius = (locationFilterConfig_defaultRadius === null || locationFilterConfig_defaultRadius === void 0 ? void 0 : locationFilterConfig_defaultRadius.toString()) || '10';
    const scsUrlParts = getScsUrlPartsFromConfiguration(undefined, getConfiguration(), getEnvironmentConfig());
    const configurationFA = useServicesContext().getAdditionalService('vtp-configuration-service');
    const enabledMandatoryAreaSearch = (_a = configurationFA === null || configurationFA === void 0 ? void 0 : configurationFA.getConfiguration()) === null || _a === void 0 ? void 0 : _a.enableMandatoryAreaSearch;
    const localStorageLocationSearchInputKey = enabledMandatoryAreaSearch
        ? `VTP_${scsUrlParts.marketPath}_mandatoryLocationSearch--input`
        : `VTP_${scsUrlParts.marketPath}_locationSearch--input`;
    const mapViewLabel = useI18n({
        id: `nemo.sc.filter.locationfilter.mapView`,
        defaultMessage: 'Map view',
    });
    const listViewLabel = useI18n({
        id: `nemo.sc.filter.locationfilter.listView`,
        defaultMessage: 'List view',
    });
    const marketInitialSearchInput = useI18n({
        id: `nemo.sc.filter.locationfilter.marketInitialSearchInput`,
        defaultMessage: countryISO,
    });
    const applyFilters = useApplyFilters();
    const filterData = useFilterData();
    const clearAllDealerFilters = useClearAllDealerFilters();
    const isDesktop = useDesktopOrMobileView('1024px') === 'desktop';
    const isMobile = useDesktopOrMobileView('767px') === 'mobile';
    const [mapRefNode, setMapRefNode] = useState();
    const { isClient } = useClientServerUtils();
    const [mobileNavBarState, setMobileNavBarState] = useState('nav-bar-location-search-mobile--list-view');
    const isMobileMapView = !isDesktop && mobileNavBarState === 'nav-bar-location-search-mobile--map-view';
    const contentService = useServicesContext().getAdditionalService('audi-content-service');
    const content = contentService.getContent().fields;
    const mileageUnit = (content === null || content === void 0 ? void 0 : content.mileageUnit) || 'km';
    const prevStateRadius = useRef(null);
    const { selectedDealer, setSelectedDealer, previousRadiusMarker, setPreviousRadiusMarker, dealersList, setDealersList, allDealersIsChecked, handleAllDealersCheck, isLoadingNewDealerData, setIsLoadingNewDealerData, combineDealersAndFiltersData, createMarkers, markersRef, } = useMarkersAndDealersService(map);
    const mapRef = useCallback((node) => {
        setMapRefNode(node);
    }, [setMapRefNode]);
    const renderMap = (status) => {
        if (status === Status.FAILURE) {
            return React.createElement("div", null, "Map failed to load");
        }
        if (status === Status.LOADING) {
            return React.createElement(Loader, { monochrome: true });
        }
    };
    const hasSearchedLocationOnOverlayOpening = useRef(null);
    useEffect(() => {
        const localStorage = new ExpiringStorage(localStorageLocationSearchInputKey, 30);
        hasSearchedLocationOnOverlayOpening.current = !!localStorage.readEntry();
    }, []);
    useEffect(() => {
        if (mapRefNode && inputRef.current) {
            const mapInstance = initializeMap(mapRefNode, isMobile);
            setMap(mapInstance);
            initializeAutocomplete(country, inputRef, localStorageLocationSearchInputKey, setSearchedCoords);
        }
    }, [mapRefNode, inputRef.current]);
    useEffect(() => {
        if ((isDesktop && map && wholeMarketDealersFetch) || (!isDesktop && wholeMarketDealersFetch)) {
            if (!isClient)
                return;
            const fetchDealers = () => __awaiter(void 0, void 0, void 0, function* () {
                setIsLoadingNewDealerData(true);
                const dealerResponse = yield getDealers(getConfiguration(), getEnvironmentConfig());
                combineDealersAndFiltersData(dealerResponse, filterData);
                removeRadiusMarker(previousRadiusMarker, setPreviousRadiusMarker);
                setWholeMarketDealersFetch(false);
            });
            fetchDealers().catch(console.error);
            if (inputRef.current)
                inputRef.current.value = marketInitialSearchInput;
        }
    }, [map, wholeMarketDealersFetch, isClient]);
    useEffect(() => {
        if (searchedCoords && !radius) {
            setRadius(defaultRadius);
        }
        else if (searchedCoords && radius) {
            if (!isClient)
                return;
            if (!radius)
                setRadius(defaultRadius);
            const firstAutocompleteSearch = prevStateRadius.current === null;
            const firedByChangedRadius = prevStateRadius.current !== radius;
            const doubleSearchOfSameLocation = (prevSearchedCoords === null || prevSearchedCoords === void 0 ? void 0 : prevSearchedCoords.latitude) === searchedCoords.latitude;
            const fetchDealers = (filterResponse) => __awaiter(void 0, void 0, void 0, function* () {
                setIsLoadingNewDealerData(true);
                const dealerResponse = yield getDealers(getConfiguration(), getEnvironmentConfig(), searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.latitude, searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.longitude, radius);
                combineDealersAndFiltersData(dealerResponse, filterResponse);
                if (map) {
                    const zeroDealersFound = dealerResponse.totalCount === 0;
                    setRadiusMarker(previousRadiusMarker, setPreviousRadiusMarker, map, searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.latitude, searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.longitude, radius, defaultRadius, zeroDealersFound);
                }
            });
            const geoFilterDTO = {
                id: 'geo',
                active: true,
                type: 'location-filter',
                values: [
                    searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.latitude,
                    searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.longitude,
                    radius,
                    mileageUnit,
                    inputRef.current.value,
                ],
                preset: enabledMandatoryAreaSearch,
            };
            const requestData = () => __awaiter(void 0, void 0, void 0, function* () {
                const preparedFilterData = shouldBeUsedFilterDataWithoutClearingDealers(firedByChangedRadius, firstAutocompleteSearch, hasSearchedLocationOnOverlayOpening.current)
                    ? yield applyFilters(geoFilterDTO)
                    : yield clearAllDealerFilters(geoFilterDTO);
                if (preparedFilterData)
                    fetchDealers(preparedFilterData);
            });
            if (!doubleSearchOfSameLocation || firedByChangedRadius)
                requestData().catch(console.error);
            prevStateRadius.current = radius;
            changedByLocateMe.current = false;
            hasSearchedLocationOnOverlayOpening.current = false;
            setPrevSearchedCoords({
                latitude: searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.latitude,
                longitude: searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.longitude,
            });
            setWholeMarketDealersFetch(false);
        }
    }, [searchedCoords, radius]);
    useEffect(() => {
        if (isMobileMapView && dealersList) {
            createMarkers(dealersList);
            if (searchedCoords) {
                const zeroDealersFound = dealersList.length === 0;
                setRadiusMarker(previousRadiusMarker, setPreviousRadiusMarker, map, searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.latitude, searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.longitude, radius, defaultRadius, zeroDealersFound);
            }
        }
    }, [mobileNavBarState, map]);
    useEffect(() => {
        if (isDesktop && consent && dealersList) {
            createMarkers(dealersList);
            if (searchedCoords) {
                const zeroDealersFound = dealersList.length === 0;
                setRadiusMarker(previousRadiusMarker, setPreviousRadiusMarker, map, searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.latitude, searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.longitude, radius, defaultRadius, zeroDealersFound);
            }
        }
    }, [consent, map]);
    useEffect(() => {
        var _a, _b, _c, _d;
        const dealersFilterData = (_b = (_a = filterData === null || filterData === void 0 ? void 0 : filterData.groups) === null || _a === void 0 ? void 0 : _a.dealers) === null || _b === void 0 ? void 0 : _b.members;
        if (dealersFilterData && dealersList) {
            const incomingDealerData = createDealersData(dealersList, (_d = (_c = filterData === null || filterData === void 0 ? void 0 : filterData.groups) === null || _c === void 0 ? void 0 : _c.dealers) === null || _d === void 0 ? void 0 : _d.members);
            if (doDealerDataDiffer(incomingDealerData, dealersList)) {
                const sortedDealersWithCars = [];
                const sortedDealersWithoutCars = [];
                incomingDealerData.forEach((item) => {
                    if (item.count !== 0) {
                        sortedDealersWithCars.push(item);
                    }
                    else {
                        sortedDealersWithoutCars.push(item);
                    }
                });
                const sortedDealers = sortedDealersWithCars.concat(sortedDealersWithoutCars);
                setDealersList(sortedDealers);
                if (markersRef) {
                    updateMarkerIcons(incomingDealerData, markersRef);
                }
            }
        }
    }, [(_c = (_b = filterData === null || filterData === void 0 ? void 0 : filterData.groups) === null || _b === void 0 ? void 0 : _b.dealers) === null || _c === void 0 ? void 0 : _c.members]);
    return (React.createElement(LocationFilterBodyContainer, null,
        !isDesktop && (React.createElement(NavigationBar, { id: "nav-bar-location-search-mobile", selected: mobileNavBarState, onSelect: (id) => {
                setMobileNavBarState(id);
            } },
            React.createElement(NavigationItem, { disabled: isLoadingNewDealerData, id: "nav-bar-location-search-mobile--map-view" }, mapViewLabel),
            React.createElement(NavigationItem, { disabled: isLoadingNewDealerData, id: "nav-bar-location-search-mobile--list-view" }, listViewLabel))),
        React.createElement(DealersListWrapper, { consent: consent, allDealersIsChecked: allDealersIsChecked, dealersList: dealersList, inputRef: inputRef, isLoadingNewDealerData: isLoadingNewDealerData, mobileNavBarState: mobileNavBarState, selectedDealer: selectedDealer, visibleIn: visibleIn, handleAllDealersCheck: handleAllDealersCheck, setSelectedDealer: setSelectedDealer }),
        React.createElement(MapWrapper, { consent: consent, dealersList: dealersList, inputRef: inputRef, isLoadingNewDealerData: isLoadingNewDealerData, mapRef: mapRef, mobileNavBarState: mobileNavBarState, renderMap: renderMap, setSelectedDealer: setSelectedDealer }),
        React.createElement(MapBlockedWrapper, { consent: consent, mobileNavBarState: mobileNavBarState, mapConsentButtonText: mapConsentButtonText, openConsentOverlay: openConsentOverlay, setIsTwoClickOverlayOpened: setIsTwoClickOverlayOpened }),
        !isDesktop && (React.createElement(SelectAllDealersCheckbox, { allDealersIsChecked: allDealersIsChecked, dealersList: dealersList, visibleIn: visibleIn, handleAllDealersCheck: handleAllDealersCheck }))));
};
export default LocationFilterBody;
