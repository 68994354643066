import ReactDOM from 'react-dom';
import React, { useRef, useState, useEffect } from 'react';
import { ConsentRequestWrapper, LocateMeButton, LocationInputWrapper, MasOverlayContainer, MasOverlayHeadlineWrapper, SearchWrapper, SearchStyle, StyledSelect, StyledResultsButton, CookieConsentWrapper, autocompleteCSS, CloseButtonWrapper, StyledCloseButton, } from '../styles/MasOverlay.styles';
import { Text, Toggle } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { ConsentModal, saveConsentToStorage, useGoogleConsent } from '@oneaudi/two-click';
import { CookieConsentRequest } from './CookieConsentRequest';
import { Search } from '@audi/audi-ui-react';
import { FilterContext } from '../FilterContext';
import { useServicesContext } from '../../../context';
import { useSelector, useDispatch } from 'react-redux';
import { ACTIONS, SELECTORS, ExpiringStorage } from '@oneaudi/stck-store';
import { formatCount } from '../../../utils';
import { setAddressFromLatLngGeocoder, updateLocationSearchInputLocalStorage, initializeInitialStateFromLocalStorage, } from '../utils/locationFilterHelpers';
import { getScsUrlPartsFromConfiguration } from '../../../hooks';
import { initializeAutocomplete } from '../utils';
import { useApplyFilters } from '../FilterContextHooks';
import { DangerousElement } from '../../../components';
import { trackClicks, trackViewChange } from '../MAStracking';
export const MasOverlay = ({ libraryLoaded, closeMasOverlay, googleCookieConsent, }) => {
    var _a;
    const context = React.useContext(FilterContext);
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    const layerManager = useServicesContext().getAdditionalService('layer-manager');
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    const scsUrlParts = getScsUrlPartsFromConfiguration(undefined, getConfiguration(), getEnvironmentConfig());
    const { locationFilterConfig_radiusOptions, locationFilterConfig_defaultRadius } = audiContentService.getContent().fields;
    const consent = useGoogleConsent();
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const getBooleanFromQueryParam = (param) => {
        if (param === null)
            return false;
        return param.toLowerCase() === 'true';
    };
    const googlemapsCookieConsentSwitch = getBooleanFromQueryParam(urlParams.get('googleCookie')) || googleCookieConsent;
    const localStorageLocationSearchInputKey = `VTP_${scsUrlParts.marketPath}_mandatoryLocationSearch--input`;
    const { radius, setRadius, searchedCoords, setWholeMarketDealersFetch, setSearchedCoords, setPrevSearchedCoords, } = context;
    const radiusOptions = locationFilterConfig_radiusOptions || [10, 20, 50, 100, 200];
    const defaultRadius = (locationFilterConfig_defaultRadius === null || locationFilterConfig_defaultRadius === void 0 ? void 0 : locationFilterConfig_defaultRadius.toString()) || '10';
    const { mileageUnit } = audiContentService.getContent().fields;
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const country = localeService.countryCode;
    const locale = `${localeService.language}-${localeService.countryCode}`;
    const totalResults = useSelector((state) => SELECTORS.VEHICLES.getTotalCountState(state));
    const formattedResultsCount = formatCount(totalResults, locale);
    const inputRef = useRef(null);
    const prevStateRadius = useRef(null);
    const changedByLocateMe = useRef(false);
    const [locationInputValue, setLocationInputValue] = useState('');
    const [twoClickOverlayOpened, setIsTwoClickOverlayOpened] = useState(false);
    const [isLoadingNewFilterData, setIsLoadingNewFilterData] = useState(false);
    const [validLocationIsSearched, setValidLocationIsSearched] = useState(false);
    useSelector((state) => {
        const getLoadingStateFromRedux = SELECTORS.UI.getLoadingState(state);
        if (getLoadingStateFromRedux !== isLoadingNewFilterData) {
            setIsLoadingNewFilterData(getLoadingStateFromRedux);
        }
    });
    const applyFilters = useApplyFilters();
    const handleLocateMeClick = () => {
        changedByLocateMe.current = true;
        const options = {
            enableHighAccuracy: true,
            timeout: 10000,
        };
        const successCallback = (position) => {
            setAddressFromLatLngGeocoder(position, inputRef, localStorageLocationSearchInputKey, setLocationInputValue, context);
        };
        const errorCallback = (error) => {
            console.error(error);
        };
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
        trackClicks(trackingService, 'mandatory area search - locate me button', '', 'other', '', '');
    };
    const [focusLayerNode, setFocusLayerNode] = useState();
    const [focusLayer, setFocusLayer] = useState();
    const focusLayerRef = React.useCallback((node) => {
        setFocusLayerNode(node);
    }, [setFocusLayerNode]);
    const openConsentOverlay = () => {
        const layer = layerManager.openFocusLayer(() => React.createElement("div", { ref: focusLayerRef }), {}, {
            userCloseable: false,
            size: "B",
        });
        setFocusLayer(layer);
        setIsTwoClickOverlayOpened(true);
        saveConsentToStorage(false);
    };
    const localStorage = new ExpiringStorage(localStorageLocationSearchInputKey, 30);
    const inputDataFromLocalStorage = localStorage.readEntry();
    const hasEnabledGoogleCookieGateAndGrantedConsent = googleCookieConsent && consent;
    useEffect(() => {
        trackViewChange(trackingService, googleCookieConsent
            ? 'vtp mandatory area search layer - consent dialog'
            : 'vtp mandatory area search layer - area search dialog');
    }, []);
    useEffect(() => {
        var _a;
        if ((hasEnabledGoogleCookieGateAndGrantedConsent && inputRef.current) ||
            (!googleCookieConsent && inputRef.current)) {
            if (inputDataFromLocalStorage) {
                setWholeMarketDealersFetch(false);
                initializeInitialStateFromLocalStorage(inputRef, inputDataFromLocalStorage, setRadius, setSearchedCoords);
                setLocationInputValue((_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.value);
            }
        }
    }, [inputRef.current]);
    const prevTwoClickOverlayOpened = useRef(false);
    useEffect(() => {
        if (twoClickOverlayOpened) {
            prevTwoClickOverlayOpened.current = true;
        }
        else if (prevTwoClickOverlayOpened.current && !twoClickOverlayOpened) {
            trackViewChange(trackingService, googleCookieConsent
                ? 'vtp mandatory area search layer - consent dialog'
                : 'vtp mandatory area search layer - area search dialog');
            prevTwoClickOverlayOpened.current = false;
        }
    }, [twoClickOverlayOpened]);
    useEffect(() => {
        if (locationInputValue === '')
            setValidLocationIsSearched(false);
    }, [locationInputValue]);
    useEffect(() => {
        if (consent && twoClickOverlayOpened && focusLayer) {
            focusLayer.close();
            setIsTwoClickOverlayOpened(false);
        }
        else if (!consent && inputDataFromLocalStorage) {
            localStorage.clearStorage();
        }
        else if (!consent) {
            dispatch(ACTIONS.UI.setLoader({ state: false, feature: 'tiles' }));
        }
    }, [consent, twoClickOverlayOpened, focusLayer]);
    useEffect(() => {
        if (libraryLoaded && inputRef.current) {
            initializeAutocomplete(country, inputRef, localStorageLocationSearchInputKey, setSearchedCoords, true);
        }
    }, [libraryLoaded, inputRef.current]);
    useEffect(() => {
        if (searchedCoords && !radius) {
            setRadius(defaultRadius);
        }
        else if (searchedCoords && radius && inputRef.current.value) {
            if (!radius)
                setRadius(defaultRadius);
            setValidLocationIsSearched(true);
            const geoPresetDTO = {
                id: 'geo',
                active: true,
                type: 'location-filter',
                values: [
                    searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.latitude,
                    searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.longitude,
                    radius,
                    mileageUnit,
                    inputRef.current.value,
                ],
                preset: true,
            };
            applyFilters(geoPresetDTO);
            prevStateRadius.current = radius;
            changedByLocateMe.current = false;
            setPrevSearchedCoords({
                latitude: searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.latitude,
                longitude: searchedCoords === null || searchedCoords === void 0 ? void 0 : searchedCoords.longitude,
            });
            setWholeMarketDealersFetch(false);
        }
    }, [searchedCoords, radius]);
    useEffect(() => {
        if ((hasEnabledGoogleCookieGateAndGrantedConsent && radius) ||
            (!googleCookieConsent && radius)) {
            if (inputRef.current.value)
                setValidLocationIsSearched(true);
            updateLocationSearchInputLocalStorage(localStorageLocationSearchInputKey, 'inputName', inputRef.current.value);
            updateLocationSearchInputLocalStorage(localStorageLocationSearchInputKey, 'radius', radius);
        }
    }, [radius]);
    const clearSearchInput = () => {
        updateLocationSearchInputLocalStorage(localStorageLocationSearchInputKey, 'inputName', '');
        inputRef.current.value = '';
        setRadius(undefined);
    };
    const masOverlayHeadlineLabel = useI18n({
        id: `nemo.sc.filter.masOverlay.masOverlayHeadlineLabel`,
        defaultMessage: 'Where do you want to look?',
    });
    const masOverlayLocationRequest = useI18n({
        id: `nemo.sc.filter.masOverlay.masOverlayLocationRequest`,
        defaultMessage: 'Please enter your location to view vehicles available in your area:',
    });
    const consentModalHeadline = useI18n({
        id: `google-maps-two-click-consent.headline`,
        defaultMessage: '',
    });
    const consentModalBody = useI18n({
        id: `google-maps-two-click-consent.copyText`,
        defaultMessage: '',
    });
    const consentModalToggle = useI18n({
        id: `google-maps-two-click-consent.toggleLabel`,
        defaultMessage: '',
    });
    const locationSearchInputLabel = useI18n({
        id: `nemo.sc.filter.masOverlay.masOverlaySearchInputLabel`,
        defaultMessage: 'Location / Post code',
    });
    const clearLocationSearchInputButtonAriaLabel = useI18n({
        id: `nemo.sc.filter.masOverlay.clearLocationSearchInputButtonAriaLabel`,
        defaultMessage: 'Clear the text in the location search input field',
    });
    const locateMeButtonAriaLabel = useI18n({
        id: `nemo.sc.filter.masOverlay.locateMeButtonAriaLabel`,
        defaultMessage: 'Use your location',
    });
    const distanceRadiusLabel = useI18n({
        id: `nemo.sc.filter.masOverlay.distanceRadiusLabel`,
        defaultMessage: 'Radius',
    });
    const resultsButtonLabel = useI18n({
        id: `nemo.sc.filter.resultsButton`,
        defaultMessage: '',
    });
    const locationConsentButtonText = useI18n({
        id: `nemo.sc.filter.masOverlay.mapConsentButtonText`,
        defaultMessage: 'Grant consent',
    });
    const contentText = useI18n({
        id: `nemo.sc.filter.locationfilter.cookieConsentControl.Content`,
        defaultMessage: 'You have given your consent to the integration of Google Maps (including integrated Google Fonts). You can revoke your consent at any time in the future by deactivating the slider with one click. You can find further information in our data protection notice.',
    });
    const toggleText = useI18n({
        id: `nemo.sc.filter.locationfilter.cookieConsentControl.Toggle`,
        defaultMessage: 'Revoke consent and block content',
    });
    return (React.createElement(MasOverlayContainer, null,
        React.createElement(MasOverlayHeadlineWrapper, null,
            React.createElement(Text, { as: "h2", variant: "order2", spaceStackEnd: "m" }, masOverlayHeadlineLabel),
            React.createElement(Text, { as: "span", variant: "copy1" }, masOverlayLocationRequest)),
        googlemapsCookieConsentSwitch && !consent && (React.createElement(ConsentRequestWrapper, null,
            React.createElement(CookieConsentRequest, { mapConsentButtonText: locationConsentButtonText, openConsentOverlay: openConsentOverlay, setIsTwoClickOverlayOpened: setIsTwoClickOverlayOpened, showIcon: false, isMas: true }))),
        React.createElement(React.Fragment, null, twoClickOverlayOpened &&
            focusLayerNode &&
            ReactDOM.createPortal(React.createElement(React.Fragment, null,
                React.createElement(CloseButtonWrapper, null,
                    React.createElement(StyledCloseButton, { variant: "icon-tertiary", icon: "cancel", onClick: () => {
                            focusLayer === null || focusLayer === void 0 ? void 0 : focusLayer.close();
                            setIsTwoClickOverlayOpened(false);
                        } })),
                React.createElement(ConsentModal, { theme: "light", headerText: consentModalHeadline, bodyText: consentModalBody, toggleText: consentModalToggle, showCloseButton: true, showRedirectButton: false, closeButtonCallback: () => {
                        focusLayer === null || focusLayer === void 0 ? void 0 : focusLayer.close();
                        setIsTwoClickOverlayOpened(false);
                        trackViewChange(trackingService, '3');
                    }, twoClickStyle: {
                        position: 'relative',
                        padding: 0,
                        top: 'unset',
                        transform: 'unset',
                        maxHeight: 'unset',
                        width: 'unset',
                        left: 'unset',
                    }, twoClickCancelCtaStyle: {
                        display: 'none',
                    } })), focusLayerNode)),
        React.createElement(LocationInputWrapper, null,
            React.createElement(SearchWrapper, null,
                React.createElement("style", null, autocompleteCSS),
                React.createElement(SearchStyle, null,
                    React.createElement(Search, { inputRef: inputRef, disabled: !consent && googlemapsCookieConsentSwitch, value: undefined, onChange: (e) => {
                            setLocationInputValue(e.target.value);
                        }, inputId: "locationSearchInput", label: locationInputValue || ((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.value) ? '' : locationSearchInputLabel, ariaLabel: clearLocationSearchInputButtonAriaLabel, placeholder: "" })),
                React.createElement(LocateMeButton, { variant: "icon-tertiary", icon: "gps", size: "small", disabled: !consent && googlemapsCookieConsentSwitch, onClick: () => handleLocateMeClick(), "aria-label": locateMeButtonAriaLabel })),
            React.createElement(StyledSelect, { inputId: "select_radius", label: distanceRadiusLabel, onChange: (e) => {
                    setRadius(e.target.value);
                }, value: radius, disabled: !locationInputValue ||
                    (!consent && googlemapsCookieConsentSwitch) ||
                    !validLocationIsSearched, hideLabelOptional: true }, radiusOptions.map((radiusOption, index) => {
                return (React.createElement("option", { value: radiusOption, key: index },
                    "+ ",
                    radiusOption,
                    " ",
                    mileageUnit || 'km'));
            }))),
        googlemapsCookieConsentSwitch && consent && (React.createElement(CookieConsentWrapper, null,
            React.createElement("div", null,
                React.createElement(Text, { as: "p", variant: "copy1" },
                    React.createElement(DangerousElement, { html: contentText })),
                React.createElement(Toggle, { inputId: "toggle__with-labels", spaceStackStart: "m", on: consent, onChange: () => {
                        saveConsentToStorage(false);
                        clearSearchInput();
                    } }, toggleText)))),
        React.createElement(StyledResultsButton, { "data-testid": "ResultButton", variant: "primary", size: "medium", spaceStackStart: "m", onClick: () => {
                closeMasOverlay();
                trackClicks(trackingService, 'mandatory area search - show result and close layer', `${formattedResultsCount} ${resultsButtonLabel}`, 'button', searchedCoords.autolocateMe ? 'current location' : 'manual search', `${radius + (mileageUnit || 'km')}`, true);
            }, loading: isLoadingNewFilterData, disabled: !validLocationIsSearched || (googleCookieConsent && !consent), "data-tracking-exclude": true }, `${formattedResultsCount} ${resultsButtonLabel}`)));
};
