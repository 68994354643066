import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Text, Icon } from '@audi/audi-ui-react-v2';
import { ModelFilterAccordionSectionContent, ModelFilterAccordionSectionPanel, ModelFilterAccordionSectionPanelContent, StyledButton, Wrapper, StyledText, TextWrapper, StyledIcon, CarlineGroupAndAmountWrapper, ChosenModelsWrapper, CarlineGroupCheckboxes, AllModelsCheckbox, } from '../styles/ModelFilter.styles';
import { noop, setPanelHeight } from '../utils/utils';
import CheckboxSimple from './CheckboxSimple';
import { formatCount } from '../../../utils';
import { useServicesContext } from '../../../context';
import { useI18n } from '@oneaudi/i18n-context';
import { trackClicks } from '../tracking';
import { useApplyFilters, useFilterData } from '../FilterContextHooks';
import { ModelImage } from './ModelImage';
import { FilterContext } from '../FilterContext';
import { handleChangeAllCarlineModels } from '../../utils';
export const ModelFilterAccordionSection = ({ carlineGroup, carlineGroupName, carlineGroupCheckboxMembers, carlineGroupPhoto, carlineNames, filterCategory, group, layoutWidth, nthChild, visibleIn, open, onToggle = noop, onKeyDown = noop, toggleRef, }) => {
    const filterData = useFilterData();
    const applyFilters = useApplyFilters();
    const i18nCarlineGroupName = useI18n({
        id: `nemo.sc.filter.filtergroups.carline.${carlineGroup}`,
        defaultMessage: carlineGroup,
    });
    const modelsLabel = useI18n({
        id: `nemo.sc.filter.filtergroups.carline.models`,
        defaultMessage: 'models',
    });
    const chosenModelLabel = useI18n({
        id: `nemo.sc.filter.filtergroups.carline.chosenModel`,
        defaultMessage: 'model chosen',
    });
    const chosenModelsLabel = useI18n({
        id: `nemo.sc.filter.filtergroups.carline.chosenModels`,
        defaultMessage: 'models chosen',
    });
    const carlineGroupPhotoNotAvailableText = useI18n({
        id: `nemo.sc.filter.filtergroups.carline.imageNotFoundText`,
        defaultMessage: 'No image available',
    });
    const context = React.useContext(FilterContext);
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    let { lightMode } = audiContentService.getContent().fields;
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const locale = `${localeService.language}-${localeService.countryCode}`;
    const panelId = `${carlineGroup}__panel`;
    const panelRef = useRef(null);
    const contentRef = useRef(null);
    const sectionRef = useRef(null);
    const [carlineGroupItems, setCarlineGroupItems] = useState();
    const [allCarlineModelsAreChecked, setAllCarlineModelsAreChecked] = useState(false);
    const handleToggle = useCallback(() => {
        onToggle(!open);
        const trackingEventName = open
            ? 'vtp filter - click on category - close accordion'
            : 'vtp filter - click on category - open accordion';
        trackClicks(trackingService, trackingEventName, `${i18nCarlineGroupName} (${countAllCarsOfCarline()})`, 'icon', filterCategory, false);
    }, [open, onToggle]);
    useEffect(() => {
        if (contentRef.current && panelRef.current) {
            setPanelHeight(panelRef.current, `${contentRef.current.scrollHeight}px`);
            if (typeof window !== 'undefined') {
                window.requestAnimationFrame(() => {
                    if (panelRef.current && contentRef.current) {
                        setPanelHeight(panelRef.current, open ? `${contentRef.current.scrollHeight}px` : '0px', true);
                    }
                });
            }
        }
    }, [open]);
    useEffect(() => {
        const carlineGroupItemsArray = [];
        carlineGroupCheckboxMembers.forEach((carlineGroupCheckboxMember) => {
            const carlineGroupCheckboxMemberData = group.items.find((item) => item.itemHeadline === carlineGroupCheckboxMember);
            if (carlineGroupCheckboxMemberData)
                carlineGroupItemsArray.push(carlineGroupCheckboxMemberData);
        });
        setCarlineGroupItems(carlineGroupItemsArray);
    }, [group.items]);
    useEffect(() => {
        if (filterData) {
            let allAvailableCarlineGroupMembersAreSelected = true;
            carlineGroupCheckboxMembers.forEach((carline) => {
                const carlineFilterData = filterData.groups.carline.members[carline];
                if (carlineFilterData.count > 0 && !carlineFilterData.active)
                    allAvailableCarlineGroupMembersAreSelected = false;
            });
            setAllCarlineModelsAreChecked(allAvailableCarlineGroupMembersAreSelected);
        }
    }, [filterData]);
    const countSelectedCars = useCallback(() => {
        var _a, _b, _c;
        const appliedFilters = filterData === null || filterData === void 0 ? void 0 : filterData.filterOptions;
        const carlineGroupData = appliedFilters === null || appliedFilters === void 0 ? void 0 : appliedFilters[carlineGroup];
        const amountSelectedFilters = (carlineGroupData === null || carlineGroupData === void 0 ? void 0 : carlineGroupData.allSelected)
            ? (_b = (_a = carlineGroupData === null || carlineGroupData === void 0 ? void 0 : carlineGroupData.selectedFilter[0]) === null || _a === void 0 ? void 0 : _a.remove) === null || _b === void 0 ? void 0 : _b.length
            : (_c = carlineGroupData === null || carlineGroupData === void 0 ? void 0 : carlineGroupData.selectedFilter) === null || _c === void 0 ? void 0 : _c.length;
        return amountSelectedFilters || 0;
    }, [filterData]);
    const handleChange = () => {
        return handleChangeAllCarlineModels(applyFilters, filterData, carlineGroupCheckboxMembers, allCarlineModelsAreChecked, setAllCarlineModelsAreChecked, carlineGroup, carlineGroupName, filterCategory);
    };
    const countAllCarsOfCarline = () => {
        let totalCarsInCarline = 0;
        const checkboxFiltersData = context.filterData.groups;
        carlineGroupItems === null || carlineGroupItems === void 0 ? void 0 : carlineGroupItems.forEach((carlineGroupItem) => {
            const checkboxFilterData = checkboxFiltersData.carline.members[carlineGroupItem.itemHeadline];
            totalCarsInCarline += checkboxFilterData.count;
        });
        return formatCount(totalCarsInCarline, locale).toString();
    };
    const accordionSectionPanelProps = {
        ref: panelRef,
        open,
        id: panelId,
    };
    return (React.createElement(ModelFilterAccordionSectionContent, { ref: sectionRef },
        React.createElement(StyledButton, { type: "button", id: carlineGroupName, "aria-controls": panelId, "aria-expanded": open, open: open, nthChild: nthChild, onClick: handleToggle, onKeyDown: onKeyDown, ref: toggleRef, lightMode: lightMode },
            React.createElement(ModelImage, { carlineGroup: carlineGroup, carlineGroupPhoto: carlineGroupPhoto, carlineGroupPhotoNotAvailableText: carlineGroupPhotoNotAvailableText, open: open }),
            React.createElement(TextWrapper, { nthChild: nthChild },
                React.createElement(Wrapper, null,
                    React.createElement(CarlineGroupAndAmountWrapper, null,
                        React.createElement(Text, { as: "span", variant: "copy1", weight: "bold" }, i18nCarlineGroupName),
                        ' ',
                        React.createElement(Text, { as: "span", variant: "copy1" },
                            "(",
                            countAllCarsOfCarline(),
                            ")")),
                    React.createElement(StyledIcon, { name: "arrow-down", size: "small", variant: "primary", open: open })),
                React.createElement(StyledText, { variant: "copy2" },
                    carlineGroupCheckboxMembers.length,
                    " ",
                    modelsLabel)),
            countSelectedCars() > 0 && (React.createElement(ChosenModelsWrapper, { open: open, nthChild: nthChild },
                React.createElement(Icon, { name: "select", size: "small", variant: "primary", spaceInlineEnd: "xs" }),
                React.createElement(Text, { as: "span", variant: "copy2" },
                    countSelectedCars(),
                    " ",
                    countSelectedCars() > 1 ? chosenModelsLabel : chosenModelLabel)))),
        React.createElement(ModelFilterAccordionSectionPanel, Object.assign({}, accordionSectionPanelProps),
            React.createElement(ModelFilterAccordionSectionPanelContent, { ref: contentRef, open: open },
                React.createElement(AllModelsCheckbox, { inputId: `checkbox${carlineGroup}_${visibleIn}`, checked: allCarlineModelsAreChecked, spaceStackEnd: "l", disabled: countAllCarsOfCarline() === '0', onChange: handleChange },
                    carlineGroupName,
                    " (",
                    countAllCarsOfCarline(),
                    ")"),
                React.createElement(CarlineGroupCheckboxes, { layoutWidth: layoutWidth }, carlineGroupCheckboxMembers.map((carlineGroupMember) => {
                    const checkboxLabel = carlineNames === null || carlineNames === void 0 ? void 0 : carlineNames[carlineGroupMember];
                    const checkboxData = group.items.find((object) => object.itemHeadline === carlineGroupMember);
                    if (checkboxData && checkboxLabel) {
                        return (React.createElement(CheckboxSimple, { groupHeadline: "carline", visibleIn: visibleIn, filterCategory: filterCategory, itemHeadline: checkboxData.itemHeadline, itemText: checkboxLabel.text, key: checkboxData.itemHeadline }));
                    }
                    return null;
                }))))));
};
export default ModelFilterAccordionSection;
