export const mapButtonTypeToIconName = (type) => {
    let iconName = '';
    switch (type) {
        case 'contact':
            iconName = 'direct-mail';
            break;
        case 'nws':
        case 'bevAgency':
            iconName = 'vtp contact';
            break;
        case 'leasing':
            iconName = 'vtp click on leasing offer';
            break;
        case 'ecom':
            iconName = 'commute';
            break;
        case 'liveDemo':
            iconName = 'delivery';
            break;
        case 'liteReservation':
        case 'reserve':
        case 'reservation':
            iconName = 'mileage';
            break;
        case 'dealer':
            iconName = 'my-dealer';
            break;
        case 'financeInfo':
        case 'finance-checkout':
        case 'financeOptions':
            iconName = 'document-pricelist-dollar';
            break;
        case 'phone':
            iconName = 'voice-call';
            break;
        case 'central-customer-hotline':
            iconName = 'hotline';
            break;
        case 'details':
            iconName = 'info-circle';
            break;
        case 'whatsApp':
            iconName = 'whatsapp';
            break;
        case 'ConsumptionDisplay':
            iconName = 'consumption';
            break;
        case 'WarrantyTypeNap':
        case 'WarrantyTypeCpo':
        case 'WarrantyTypeAsgExtended':
        case 'WarrantyTypeAsg':
        case 'WarrantyTypeWarranty':
        case 'WarrantyTypePlus':
        case 'WarrantyType12Months':
        case 'WarrantyType5Years':
            iconName = 'warranty';
            break;
        case 'EmissionDisplay':
            iconName = 'co2-emission';
            break;
        case 'Mileage':
            iconName = 'mileage';
            break;
        case 'GearType':
        case 'GearBox':
            iconName = 'transmission';
            break;
        case 'FuelType':
            iconName = 'fuel-station';
            break;
        case 'InitialRegistrationDate':
        case 'ProductionYear':
        case 'SalesModelYear':
        case 'ModelYear':
        case 'VehicleInspectionDueDate':
        case 'GeneralInspection':
        case 'WarrantyExpiryDate':
            iconName = 'calendar-events';
            break;
        case 'ElectricRange':
            iconName = 'electric-range';
            break;
        case 'ExtColor':
            iconName = 'car';
            break;
        case 'UpholsteryType':
        case 'UpholsteryColor':
            iconName = 'interior-configuration';
            break;
        case 'PowerDisplay':
            iconName = 'power';
            break;
        case 'DriveType':
            iconName = 'quattro';
            break;
        case 'NumPreviousOwners':
            iconName = 'groups';
            break;
        case 'TopSpeed':
            iconName = 'top-speed';
            break;
        case 'RegistrationPlate':
            iconName = 'car';
            break;
        default:
            iconName = 'info-circle';
    }
    return iconName;
};
export const handleChangeAllCarlineModels = (applyFilters, filterData, carlineGroupCheckboxMembers, allCarlineModelsAreChecked, setAllCarlineModelsAreChecked, carlineGroup, carlineGroupName, filterCategory, isPlpQuickFilters) => {
    var _a, _b;
    const filterDTOs = [];
    const filterCarlineData = (_b = (_a = filterData === null || filterData === void 0 ? void 0 : filterData.groups) === null || _a === void 0 ? void 0 : _a.carline) === null || _b === void 0 ? void 0 : _b.members;
    carlineGroupCheckboxMembers.forEach((carlineGroupCheckboxMember) => {
        var _a;
        const carlineHasAvailableCars = ((_a = filterCarlineData[carlineGroupCheckboxMember]) === null || _a === void 0 ? void 0 : _a.count) > 0;
        const filterDTO = {
            id: carlineGroupCheckboxMember,
            active: !allCarlineModelsAreChecked,
        };
        return carlineHasAvailableCars && filterDTOs.push(filterDTO);
    });
    const currentFilter = { id: carlineGroup, values: [] };
    const componentName = isPlpQuickFilters ? 'vtp quick filter' : 'vtp filter';
    const trackingEventName = !allCarlineModelsAreChecked
        ? `${componentName} - add filter`
        : `${componentName} - remove filter`;
    const trackingData = {
        trackingEventName,
        trackingLabel: carlineGroupName,
        trackingValue: filterCategory,
        changedFilter: [currentFilter],
    };
    setAllCarlineModelsAreChecked(!allCarlineModelsAreChecked);
    applyFilters(filterDTOs, trackingData);
};
