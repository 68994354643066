import styled from 'styled-components';
import React from 'react';
import { Checkbox } from '@audi/audi-ui-react-v2';
export const FieldsetWrapper = styled.div `
  width: 100%;
  margin-bottom: ${(props) => props.isPlpQuickFilters ? '48px' : `var(${props.theme.responsive.spacing.s})`};

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    /* ColorTiles are an exception as they start out side-by-side from m on */
    width: ${(props) => props.layoutWidth === '50' && props.isColorTypeCheckbox ? 'calc(50% - 20px)' : '100%'};
    margin-bottom: ${(props) => props.isPlpQuickFilters ? '48px' : `var(${props.theme.responsive.spacing.s})`};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-bottom: ${(props) => props.isPlpQuickFilters ? '48px' : `var(${props.theme.responsive.spacing.m})`};
    width: ${(props) => (props.layoutWidth === '50' ? 'calc(50% - 20px)' : '100%')};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-bottom: ${(props) => props.isPlpQuickFilters ? '48px' : `var(${props.theme.responsive.spacing.xl})`};
    width: ${(props) => (props.layoutWidth === '50' ? 'calc(50% - 34px)' : '100%')};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    margin-bottom: ${(props) => (props.isPlpQuickFilters ? '48px' : '28px')});
    width: ${(props) => (props.layoutWidth === '50' ? 'calc(50% - 26px)' : '100%')};
  }
`;
export const FieldsetOneCol = styled((props) => React.createElement("fieldset", Object.assign({}, props))) `
  /* width: 100%; */
  margin: 0;
  padding: 0;
  border-color: transparent;

  display: grid;
  column-gap: var(
    ${(props) => props.$isColorTypeCheckbox
    ? props.theme.responsive.spacing.l
    : props.theme.responsive.spacing.xxl}
  );
  grid-template-columns: ${(props) => props.$isColorTypeCheckbox ? 'repeat(2, 1fr)' : 'auto'};

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    /* ColorTiles are 3columns on 50% layouts and 4cols on 100%, checkboxes in the equipmentFilter are 4cols as well, all other filters are 2cols */
    ${(props) => {
    if ((props.$layoutWidth === '50' || props.$layoutWidth === '50/100') &&
        props.$isColorTypeCheckbox) {
        return `grid-template-columns: repeat(3, 88px)`;
    }
    if (props.$isColorTypeCheckbox) {
        return `grid-template-columns: repeat(4, 88px)`;
    }
    if (props.$isPartOfEquipmentFilter) {
        return `grid-template-columns: repeat(1, 1fr)`;
    }
    return `grid-template-columns: repeat(2, 1fr)`;
}};

    grid-auto-flow: row;
    column-gap: var(
      ${(props) => props.$isColorTypeCheckbox
    ? props.theme.responsive.spacing.xs
    : props.theme.responsive.spacing.m}
    );
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    /* ColorTiles are 4cols, all other filters are 2 or 4 cols */
    ${(props) => {
    if (props.$isColorTypeCheckbox) {
        return `grid-template-columns: repeat(4, 88px)`;
    }
    if (props.$layoutWidth === '50' ||
        props.$layoutWidth === '50/100' ||
        props.$isPartOfEquipmentFilter) {
        return `grid-template-columns: repeat(2, 1fr)`;
    }
    if (props.$isPlpQuickFilters) {
        return `grid-template-columns: repeat(1, 1fr)`;
    }
    return `grid-template-columns: repeat(4, 1fr)`;
}};
    width: ${(props) => (props.layoutWidth === '50/100' ? 'calc(50% - 20px)' : '100%')};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    /* equipmentFilter checkboxes are 1col, colorTiles 4col, the other filters are 2col */
    width: ${(props) => (props.$layoutWidth === '50/100' ? 'calc(50% - 26px)' : '100%')};
    ${(props) => {
    if (props.$isPartOfEquipmentFilter) {
        return `
            grid-template-columns: repeat(1, 1fr)
            `;
    }
    if (props.$isColorTypeCheckbox) {
        return `grid-template-columns: repeat(4, 88px)`;
    }
    if (props.$isPlpQuickFilters) {
        return `grid-template-columns: repeat(1, 1fr)`;
    }
    return `
            grid-template-columsrepeat(2, 1fr)
            `;
}};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xs}px) and (max-width: ${(props) => props.theme.breakpoints.s}px) {
    ${(props) => {
    if (props.$isPlpQuickFilters && props.$isColorTypeCheckbox) {
        return `
          grid-template-columns: repeat(4, 46px);
          column-gap: 16px;
        `;
    }
    return ``;
}}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) and (max-width: ${(props) => props.theme.breakpoints.m}px) {
    ${(props) => {
    if (props.$isPlpQuickFilters && props.$isColorTypeCheckbox) {
        return `
          grid-template-columns: repeat(5, 46px);
          column-gap: 16px;
        `;
    }
    return ``;
}}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) and (max-width: ${(props) => props.theme.breakpoints.l}px) {
    ${(props) => {
    if (props.$isPlpQuickFilters && props.$isColorTypeCheckbox) {
        return `
          grid-template-columns: repeat(4, 46px);
          column-gap: 16px;
        `;
    }
    return ``;
}}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) and (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    ${(props) => {
    if (props.$isPlpQuickFilters && props.$isColorTypeCheckbox) {
        return `
          grid-template-columns: repeat(3, 46px);
          column-gap: 16px;
        `;
    }
    return ``;
}}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) and (max-width: ${(props) => props.theme.breakpoints.xxl}px) {
    ${(props) => {
    if (props.$isPlpQuickFilters && props.$isColorTypeCheckbox) {
        return `
          grid-template-columns: repeat(4, 46px);
          column-gap: 16px;
        `;
    }
    return ``;
}}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) and (max-width: ${(props) => props.theme.breakpoints.xxl}px) {
    ${(props) => {
    if (props.$isPlpQuickFilters && props.$isColorTypeCheckbox) {
        return `
          grid-template-columns: repeat(5, 46px);
          column-gap: 16px;
        `;
    }
    return ``;
}}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    width: ${(props) => (props.$layoutWidth === '50/100' ? 'calc(50% - 34px)' : '100%')};
  }
`;
export const StyledCheckboxWrapper = styled.div `
  display: flex;
  margin-block-end: var(${(props) => props.theme.responsive.spacing.l});
  align-items: baseline;
`;
export const MaxWidthLabel = styled.div `
  width: 255px;
  max-width: 255px;
`;
export const MoreOptionsButtonsWrapper = styled.div `
  margin-bottom: 36px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-bottom: 66px;
  }

  margin-bottom: 36px;
`;
export const StyledCheckbox = styled((props) => React.createElement(Checkbox, Object.assign({}, props))) `
  align-items: ${(props) => (props.isCarTypeIconCheckbox ? 'center' : 'unset')};

  input {
    height: 20px !important;
  }
`;
export const IconLabelWrapper = styled.div `
  display: flex;
  align-items: center;
`;
export const GroupHeading = styled.div `
  display: flex;
  margin-bottom: var(
    ${(props) => props.isPlpQuickFilters ? props.theme.responsive.spacing.s : props.theme.responsive.spacing.l}
  );
`;
export const VisuallyHiddenLegend = styled.legend `
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
export const LabelCountWrapper = styled.span `
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: var(${(props) => props.theme.responsive.spacing.s});
`;
