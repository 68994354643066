import { extractGoogleMapsAuthParams } from '../plp/utils';
const useGoogleAutocomplete = (locationFilterConfig_mapsAuthQueryParams) => {
    const { googleMapsApiKey, googleMapsClient, googleMapsChannel } = extractGoogleMapsAuthParams(locationFilterConfig_mapsAuthQueryParams);
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.onload = () => resolve(true);
        if (googleMapsApiKey) {
            script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&loading=async&libraries=places`;
        }
        else {
            script.src = `https://maps.googleapis.com/maps/api/js?client=${googleMapsClient}&channel=${googleMapsChannel}&loading=async&libraries=places`;
        }
        document.body.appendChild(script);
    });
};
export default useGoogleAutocomplete;
