import { useState, useEffect } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useApplyFilters, useWholeMarketDealerData } from '../FilterContextHooks';
import { getClusterIcon, getMarkerIcon, setDealerStatus, setDealerStatusToFocused, changeIconFormerlyFocusedMarker, changeIconNewSelectedMarker, } from '../utils/locationFilterHelpers';
export const useMarkersAndDealersService = (map) => {
    const applyFilters = useApplyFilters();
    const wholeMarketDealerData = useWholeMarketDealerData();
    const [markersRef, setMarkersRef] = useState();
    const [previousRadiusMarker, setPreviousRadiusMarker] = useState(null);
    const [previousMarkerCluster, setPreviousMarkerCluster] = useState();
    const [selectedDealer, setSelectedDealer] = useState({
        dealerId: undefined,
        source: undefined,
    });
    const [dealersList, setDealersList] = useState();
    const [allDealersIsChecked, setAllDealersIsChecked] = useState(false);
    const [isLoadingNewDealerData, setIsLoadingNewDealerData] = useState(false);
    const combineDealersAndFiltersData = (dealerData, filterData) => {
        var _a, _b, _c;
        const sortedDealersWithCars = [];
        const sortedDealersWithoutCars = [];
        (_a = dealerData === null || dealerData === void 0 ? void 0 : dealerData.results) === null || _a === void 0 ? void 0 : _a.forEach((result) => {
            var _a, _b;
            const dealersFilterData = (_b = (_a = filterData === null || filterData === void 0 ? void 0 : filterData.groups) === null || _a === void 0 ? void 0 : _a.dealers) === null || _b === void 0 ? void 0 : _b.members;
            const dealerDataFromFilterEndpoint = dealersFilterData[`dealer.${result.id}`] || {
                count: 0,
                active: false,
            };
            const dealer = Object.assign(Object.assign({}, result), { dealerId: `dealer.${result.id}`, status: setDealerStatus(dealerDataFromFilterEndpoint.count === 0, dealerDataFromFilterEndpoint.active), count: dealerDataFromFilterEndpoint.count });
            if (dealer.count !== 0) {
                sortedDealersWithCars.push(dealer);
            }
            else {
                sortedDealersWithoutCars.push(dealer);
            }
        });
        const dealerList = sortedDealersWithCars.concat(sortedDealersWithoutCars);
        (_c = (_b = filterData === null || filterData === void 0 ? void 0 : filterData.activeFilters) === null || _b === void 0 ? void 0 : _b.dealer) === null || _c === void 0 ? void 0 : _c.values.forEach((dealerId) => {
            var _a, _b;
            const isSelectedDealerMissingFromDealerData = !dealerList.find((dealer) => dealer.dealerId === dealerId);
            if (isSelectedDealerMissingFromDealerData) {
                const dealersFilterData = (_b = (_a = filterData === null || filterData === void 0 ? void 0 : filterData.groups) === null || _a === void 0 ? void 0 : _a.dealers) === null || _b === void 0 ? void 0 : _b.members;
                const dealerDataFromFilterEndpoint = dealersFilterData[dealerId] || {
                    count: 0,
                    active: false,
                };
                const missingDealerDataFound = wholeMarketDealerData === null || wholeMarketDealerData === void 0 ? void 0 : wholeMarketDealerData.results.find((dealer) => dealer.id === dealerId.replace('dealer.', ''));
                if (missingDealerDataFound) {
                    const dealer = Object.assign(Object.assign({}, missingDealerDataFound), { dealerId: `dealer.${missingDealerDataFound === null || missingDealerDataFound === void 0 ? void 0 : missingDealerDataFound.id}`, status: setDealerStatus(dealerDataFromFilterEndpoint.count === 0, dealerDataFromFilterEndpoint.active), count: dealerDataFromFilterEndpoint.count });
                    dealerList.push(dealer);
                }
            }
        });
        setDealersList(dealerList);
        createMarkers(dealerList);
        setIsLoadingNewDealerData(false);
    };
    const createMarkers = (dealers) => {
        if (!map)
            return;
        removeMarkers();
        const bounds = new google.maps.LatLngBounds();
        const markerRefs = [];
        dealers.forEach((dealer) => {
            const geo = dealer.geoLocation;
            const { lat, lon } = geo;
            const marker = new google.maps.Marker({
                position: { lat, lng: lon },
                map,
                icon: getMarkerIcon(dealer.status),
                clickable: dealer.status !== 'disabled',
                zIndex: dealer.status === 'disabled' ? 1 : 2,
            });
            const markerRefObj = {
                dealerId: `dealer.${dealer.id}`,
                markerRef: marker,
            };
            marker.addListener('click', () => {
                setSelectedDealer({ dealerId: dealer.dealerId, source: 'marker' });
            });
            markerRefs.push(markerRefObj);
            const markerLatLng = new google.maps.LatLng(lat, lon);
            bounds.extend(markerLatLng);
        });
        map === null || map === void 0 ? void 0 : map.fitBounds(bounds);
        setMarkersRef(markerRefs);
        const iconCluster = getClusterIcon();
        const markers = markerRefs.map((markerRef) => {
            return markerRef.markerRef;
        });
        const renderer = {
            render({ count, position }) {
                return new google.maps.Marker({
                    label: {
                        text: count.toString(),
                        color: 'white',
                        fontSize: '12px',
                        className: 'marker-with-counter',
                    },
                    position,
                    icon: iconCluster,
                    zIndex: 1,
                });
            },
        };
        const markerCluster = new MarkerClusterer({
            map,
            markers,
            renderer,
        });
        setPreviousMarkerCluster(markerCluster);
    };
    const removeMarkers = () => {
        previousMarkerCluster === null || previousMarkerCluster === void 0 ? void 0 : previousMarkerCluster.clearMarkers();
        setPreviousMarkerCluster(null);
        if (markersRef) {
            for (let i = 0; i < markersRef.length; i++) {
                markersRef[i].markerRef.setMap(null);
                setMarkersRef([]);
            }
        }
    };
    useEffect(() => {
        var _a;
        if (selectedDealer.dealerId && dealersList) {
            const currentDealerStatus = (_a = dealersList.find((item) => {
                return item.dealerId === selectedDealer.dealerId;
            })) === null || _a === void 0 ? void 0 : _a.status;
            const newDealerStatus = setDealerStatusToFocused(currentDealerStatus === 'unchecked')
                ? 'focused'
                : 'unchecked';
            if (markersRef) {
                if (newDealerStatus === 'focused')
                    changeIconFormerlyFocusedMarker(dealersList, markersRef);
                changeIconNewSelectedMarker(markersRef, selectedDealer, newDealerStatus);
            }
            setSelectedDealer({ dealerId: undefined, source: undefined });
            const newDealerIsFocused = newDealerStatus === 'focused';
            setDealersList(dealersList.map((item) => {
                return item.dealerId === selectedDealer.dealerId
                    ? Object.assign(Object.assign({}, item), { status: newDealerStatus }) : Object.assign(Object.assign({}, item), { status: newDealerIsFocused && item.status === 'focused' ? 'checked' : item.status });
            }));
            const filterDTO = {
                id: selectedDealer.dealerId,
                active: newDealerStatus !== 'unchecked',
            };
            applyFilters(filterDTO);
        }
    }, [selectedDealer, dealersList]);
    const handleAllDealersCheck = () => {
        if (dealersList) {
            setDealersList(dealersList.map((item) => {
                return Object.assign(Object.assign({}, item), { status: setDealerStatus((item === null || item === void 0 ? void 0 : item.status) === 'disabled', !allDealersIsChecked) });
            }));
            const checkedIcon = getMarkerIcon('checked');
            const unCheckedIcon = getMarkerIcon('unchecked');
            const allAvailableDealers = dealersList.filter((item) => {
                return (item === null || item === void 0 ? void 0 : item.status) !== 'disabled';
            });
            const markerRefsForAllUndisabledDealers = allAvailableDealers === null || allAvailableDealers === void 0 ? void 0 : allAvailableDealers.map((item) => {
                return markersRef === null || markersRef === void 0 ? void 0 : markersRef.find((marker) => (marker === null || marker === void 0 ? void 0 : marker.dealerId) === (item === null || item === void 0 ? void 0 : item.dealerId));
            });
            markerRefsForAllUndisabledDealers === null || markerRefsForAllUndisabledDealers === void 0 ? void 0 : markerRefsForAllUndisabledDealers.forEach((item) => {
                var _a;
                const newIcon = !allDealersIsChecked ? checkedIcon : unCheckedIcon;
                (_a = item === null || item === void 0 ? void 0 : item.markerRef) === null || _a === void 0 ? void 0 : _a.setIcon(newIcon);
            });
            const filterDTOs = [];
            allAvailableDealers === null || allAvailableDealers === void 0 ? void 0 : allAvailableDealers.forEach((item) => {
                const filterDTO = {
                    id: item === null || item === void 0 ? void 0 : item.dealerId,
                    active: !allDealersIsChecked,
                };
                filterDTOs.push(filterDTO);
            });
            applyFilters(filterDTOs);
        }
    };
    useEffect(() => {
        if (dealersList) {
            const isSomeDealerUnchecked = dealersList.some((item) => item.status === 'unchecked');
            const hasSomeDealerAvailableCars = dealersList.some((item) => item.count > 0);
            const allAvailableDealersAreSelected = !isSomeDealerUnchecked && hasSomeDealerAvailableCars;
            setAllDealersIsChecked(allAvailableDealersAreSelected);
        }
    }, [dealersList]);
    return {
        markersRef,
        selectedDealer,
        setSelectedDealer,
        previousRadiusMarker,
        setPreviousRadiusMarker,
        dealersList,
        setDealersList,
        allDealersIsChecked,
        handleAllDealersCheck,
        isLoadingNewDealerData,
        setIsLoadingNewDealerData,
        combineDealersAndFiltersData,
        createMarkers,
    };
};
