import styled from 'styled-components';
import React from 'react';
import { Button, Text, Divider, } from '@audi/audi-ui-react-v2';
export const OptionsToolbarContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-top: var(${(props) => props.theme.responsive.spacing.l});
  margin-bottom: var(${(props) => props.theme.responsive.spacing.l});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &:last-child {
      margin-bottom: var(${(props) => props.theme.responsive.spacing.l});
    }
  }
`;
export const MobileFavouritesHeading = styled((props) => (React.createElement(Text, Object.assign({ as: "h2", variant: "order1" }, props)))) `
  margin-top: var(${(props) => props.theme.responsive.spacing.l});
  margin-bottom: var(${(props) => props.theme.responsive.spacing.xxl});
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: none;
  }
`;
export const DesktopFavouritesHeading = styled((props) => (React.createElement(Text, Object.assign({ as: "h2", variant: "order1" }, props)))) `
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: block;
    margin-bottom: var(${(props) => props.theme.responsive.spacing.xxl});
  }
`;
export const MobileOptions = styled.div `
  position: relative;

  @media (min-width: ${(props) => props.mandatoryAreaSearchActive ? props.theme.breakpoints.xl : props.theme.breakpoints.m}px) {
    display: none;
  }
`;
export const ButtonsWrapper = styled.div `
  display flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;
export const OptionsWrapper = styled.div `
  display: block;
  position: absolute;
  margin-top: var(${(props) => props.theme.responsive.spacing.xxs});
  background-color: var(${(props) => props.theme.colors.base.grey[5]});
  z-index: 4;
  max-width: 100vw;
  left: -28px;
  right: -28px;
  padding-left: 28px;
  padding-right: 28px;

  @media (min-width: ${(props) => props.mandatoryAreaSearchActive ? props.theme.breakpoints.xl : props.theme.breakpoints.m}px) {
    display: none;
  }
`;
export const StyledButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  padding: var(${(props) => props.theme.responsive.spacing.s}) 0;
  width: calc(100vw - 56px);
  ${(props) => props.mandatoryAreaSearchActive &&
    `
    @media (min-width: ${props.theme.breakpoints.m}px) {
      width: unset;
    }`};
  }}
`;
export const StyledDivider = styled((props) => React.createElement(Divider, Object.assign({}, props))) `
  width: calc(100vw - 56px);
  ${(props) => props.mandatoryAreaSearchActive &&
    `
    @media (min-width: ${props.theme.breakpoints.m}px) {
      width: unset;
    }`};
  }}
`;
export const DesktopOptions = styled.div `
  display: none;
  @media (min-width: ${(props) => props.mandatoryAreaSearchActive ? props.theme.breakpoints.xl : props.theme.breakpoints.m}px) {
    display: block;
  }
`;
export const StyledText = styled((props) => (React.createElement(Text, Object.assign({ as: "h1", variant: "order1" }, props)))) `
  margin-bottom: var(${(props) => props.theme.responsive.spacing.xxxl});
  overflow-wrap: anywhere;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-bottom: unset;
  }
`;
