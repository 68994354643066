var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { formatCount, httpRequest } from '../../../utils';
import styled from 'styled-components';
import { useI18n } from '@oneaudi/i18n-context';
export const formatFiltersForCheckboxes = (rawCheckboxFilterSCSResponse, rawCheckboxFilterTextStrings) => {
    var _a, _b, _c, _d;
    const formattedFilterArray = [];
    for (const [groupKey] of Object.entries(rawCheckboxFilterSCSResponse)) {
        const groupObject = {
            groupHeadline: groupKey,
            groupText: (_a = rawCheckboxFilterTextStrings === null || rawCheckboxFilterTextStrings === void 0 ? void 0 : rawCheckboxFilterTextStrings[groupKey]) === null || _a === void 0 ? void 0 : _a.text,
            items: [],
        };
        const membersObject = rawCheckboxFilterSCSResponse[groupKey].members;
        if (membersObject) {
            for (const [itemKey] of Object.entries(membersObject)) {
                const itemObject = {
                    itemHeadline: itemKey,
                    itemText: (_d = (_c = (_b = rawCheckboxFilterTextStrings === null || rawCheckboxFilterTextStrings === void 0 ? void 0 : rawCheckboxFilterTextStrings[groupKey]) === null || _b === void 0 ? void 0 : _b.members) === null || _c === void 0 ? void 0 : _c[itemKey]) === null || _d === void 0 ? void 0 : _d.text,
                };
                groupObject.items.push(itemObject);
            }
        }
        formattedFilterArray.push(groupObject);
    }
    return formattedFilterArray;
};
export const areArraysEqual = (a, b) => {
    if (a === b)
        return true;
    if (a == null || b == null)
        return false;
    if (a.length !== b.length)
        return false;
    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i])
            return false;
    }
    return true;
};
export const areFilterArraysEqualish = (a, b) => {
    if (a == null || b == null)
        return false;
    if (a.length !== b.length)
        return false;
    for (let i = 0; i < a.length; ++i) {
        if (a[i].label) {
            if (a[i].label !== b[i].label)
                return false;
            if (a[i].label === 'undefined') {
                return true;
            }
        }
    }
    return true;
};
const nMinMaxCalculationSteps = 50;
export const calculateMinimumScopeValue = (minScopeValue, incrementValue) => {
    let increment = incrementValue;
    const nSteps = minScopeValue / increment;
    if (nSteps > nMinMaxCalculationSteps) {
        increment = minScopeValue / nMinMaxCalculationSteps;
    }
    let resultValue = increment;
    if (minScopeValue >= 0) {
        while (resultValue / minScopeValue < 1) {
            resultValue += increment;
        }
        return resultValue;
    }
    return 0;
};
export const calculateMaximumScopeValue = (maxScopeValue, incrementValue) => {
    let increment = incrementValue;
    const nSteps = maxScopeValue / increment;
    if (nSteps > nMinMaxCalculationSteps) {
        increment = maxScopeValue / nMinMaxCalculationSteps;
    }
    let resultValue = increment;
    if (maxScopeValue >= 0) {
        while (resultValue / maxScopeValue < 1) {
            resultValue += increment;
        }
        return resultValue - increment;
    }
    return 0;
};
export const getFormattedValue = (dataOriginalValue, locale, unit) => {
    return unit
        ? `${formatCount(dataOriginalValue, locale).toString()} ${unit}`
        : `${formatCount(dataOriginalValue, locale).toString()}`;
};
export const getIncrementDataValues = (minScope, maxScope, configIncrement) => {
    let increment = configIncrement;
    const incrementArray = [];
    let i = minScope;
    const nRangeSteps = 100;
    const diff = maxScope - minScope;
    const nSteps = diff / increment;
    if (nSteps > nRangeSteps) {
        increment = Math.round(diff / nRangeSteps);
    }
    while (i <= maxScope) {
        if (i > 0)
            incrementArray.push(i);
        i += increment;
    }
    return incrementArray;
};
export const getIncrementDataValuesForMileage = (minScope, maxScope, defaultIncrement) => {
    const incrementArray = [];
    const diff = maxScope - minScope;
    let i;
    if (diff < 1000 || diff > 20000) {
        i = 0;
    }
    else {
        i = defaultIncrement;
    }
    while (i <= maxScope) {
        if (i !== 0)
            incrementArray.push(i);
        let chosenIncrementScope;
        if (i < 5000)
            chosenIncrementScope = 1000;
        else if (i < 10000)
            chosenIncrementScope = 2500;
        else if (i < 100000)
            chosenIncrementScope = 10000;
        else if (i < 1000000)
            chosenIncrementScope = 20000;
        else
            chosenIncrementScope = 10;
        i += chosenIncrementScope;
    }
    return incrementArray;
};
export const getIncrementForFinanceAmount = (minScope, maxScope) => {
    const diff = maxScope - minScope;
    let value;
    const powValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    for (const i of powValues) {
        value = Math.pow(10, i);
        if (diff <= value) {
            const prev = Math.pow(10, (i - 1));
            const next = Math.pow(10, i);
            const nearPrev = Math.abs(diff - prev);
            const nearNext = Math.abs(diff - next);
            if (nearPrev < nearNext) {
                return prev / 10;
            }
            return next / 10;
        }
    }
    return 10;
};
export const updateCheckboxFilterTexts = (scsBaseUrl, scsMarketPath, versionString, svd, sortParam) => __awaiter(void 0, void 0, void 0, function* () {
    const allCheckboxFilterTextsFromSCS = yield httpRequest(`${scsBaseUrl}${versionString}/structure/features/${scsMarketPath}?svd=${svd}&size=12&sort=${sortParam}%3Aasc`);
    return allCheckboxFilterTextsFromSCS;
});
export const noop = () => { };
export function setPanelHeight(node, value, defer = false) {
    const action = () => node.style.setProperty('--panel-height', value);
    if (defer) {
        if (typeof window !== 'undefined')
            window.requestAnimationFrame(action);
    }
    else {
        action();
    }
}
export function setHeaderButtonPosition(node, value, defer = false) {
    const action = () => node.style.setProperty('--button-position', value);
    if (defer) {
        if (typeof window !== 'undefined')
            window.requestAnimationFrame(action);
    }
    else {
        action();
    }
}
export function setOverlayVisibilityAndZIndex(node, valueVisibilty, valueZIndex, defer = false) {
    const action = () => {
        node === null || node === void 0 ? void 0 : node.style.setProperty('--overlay-visibility', valueVisibilty);
        node === null || node === void 0 ? void 0 : node.style.setProperty('--overlay-z-index', valueZIndex);
    };
    if (defer) {
        if (typeof window !== 'undefined')
            window.requestAnimationFrame(action);
    }
    else {
        action();
    }
}
export const getFiltersInCategoryArray = (filterCategory) => {
    const filtersInCategoryArray = filterCategory.filterGroups.map((filterGroup) => {
        const filterNameType = filterGroup.filterGroup;
        const lastIndex = filterNameType.lastIndexOf('.');
        const filterName = filterNameType.substring(0, lastIndex);
        const filterType = filterNameType.substring(lastIndex + 1);
        const layoutWidth = filterGroup.layoutWidth || '100';
        const isPartOfEquipmentFilter = !!filterCategory.isEquipmentFilterCategory;
        const filterObject = { filter: filterName, filterType, layoutWidth, isPartOfEquipmentFilter };
        return filterObject;
    });
    return filtersInCategoryArray;
};
export const getContentSectionTopPadding = (windowWidth) => {
    let contentSectionPaddingTop;
    switch (true) {
        case windowWidth >= 320 && windowWidth < 768:
            contentSectionPaddingTop = 40;
            break;
        case windowWidth >= 768 && windowWidth < 1024:
            contentSectionPaddingTop = 48;
            break;
        case windowWidth >= 1024 && windowWidth < 1440:
            contentSectionPaddingTop = 60;
            break;
        case windowWidth >= 1440 && windowWidth < 1920:
            contentSectionPaddingTop = 80;
            break;
        case windowWidth >= 1920:
            contentSectionPaddingTop = 100;
            break;
        default:
            contentSectionPaddingTop = 40;
    }
    return contentSectionPaddingTop;
};
export const VisuallyHidden = styled.div `
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
export const getRandomNumber = () => {
    return Math.random();
};
export const shuffleArray = (array, seed) => {
    for (let i = array.length - 1; i >= 0; i--) {
        const transformedSeed = Math.abs((Math.sin(seed + 1) * 10000) % 1);
        const j = Math.floor(transformedSeed * i);
        [array[i], array[j]] = [array[j], array[i]];
        seed++;
    }
    return array;
};
export const getAllCountersForMatches = () => {
    const noMatchesFoundCounter = useI18n({
        id: `nemo.sc.filter.locationfilter.singleMatchFoundCounter`,
        defaultMessage: 'matches',
    });
    const singleMatchFoundCounter = useI18n({
        id: `nemo.sc.filter.locationfilter.singleMatchFoundCounter`,
        defaultMessage: 'match',
    });
    const multipleMatchesFoundCounter = useI18n({
        id: `nemo.sc.filter.locationfilter.multipleMatchesFoundCounter`,
        defaultMessage: 'matches',
    });
    return [noMatchesFoundCounter, singleMatchFoundCounter, multipleMatchesFoundCounter];
};
export const getCounterForThisNumberOfMatches = (numberOfMatches) => {
    const [noMatchesFoundCounter, singleMatchFoundCounter, multipleMatchesFoundCounter] = getAllCountersForMatches();
    const counterForThisNumberOfMatches = numberOfMatches === 0
        ? noMatchesFoundCounter
        : numberOfMatches === 1
            ? singleMatchFoundCounter
            : multipleMatchesFoundCounter;
    return counterForThisNumberOfMatches;
};
