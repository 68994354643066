import React, { useEffect, useRef } from 'react';
import { DealersListWrapper, DealersListRef, RenderedDealersList, } from '../styles/LocationFilter.styles';
import { useFilterData } from '../FilterContextHooks';
import { DealersListItem } from './DealersListItem';
import { scrollDealerListItemToTop } from '../utils/locationFilterHelpers';
export const DealersListItems = ({ dealersList, visibleIn, selectedDealer, setSelectedDealer, }) => {
    const filterData = useFilterData();
    const zeroResults = (dealersList === null || dealersList === void 0 ? void 0 : dealersList.length) === 0;
    const dealerListRef = useRef();
    const dealerItemsRef = useRef([]);
    useEffect(() => {
        if (selectedDealer.dealerId) {
            scrollDealerListItemToTop(dealerItemsRef, dealerListRef, selectedDealer, dealersList);
        }
    }, [selectedDealer]);
    const renderDealersList = () => {
        dealerItemsRef.current = [];
        return dealersList === null || dealersList === void 0 ? void 0 : dealersList.map((result, index) => {
            var _a, _b;
            const dealersFilterData = (_b = (_a = filterData === null || filterData === void 0 ? void 0 : filterData.groups) === null || _a === void 0 ? void 0 : _a.dealers) === null || _b === void 0 ? void 0 : _b.members;
            const dealerDataFromFilterEndpoint = dealersFilterData[`dealer.${result.id}`] || {
                count: 0,
                active: false,
            };
            const dealerIsActive = dealerDataFromFilterEndpoint.active;
            const dealerCount = dealerDataFromFilterEndpoint.count;
            return (React.createElement(DealersListRef, { "data-dealer-id": `dealer.${result.id}`, ref: (element) => {
                    if (element) {
                        dealerItemsRef.current.splice(index, 1, element);
                    }
                }, key: index },
                React.createElement(DealersListItem, { active: dealerIsActive, visibleIn: visibleIn, result: result, isCheckedControlledByClickOnMarker: result.status === 'focused' || result.status === 'checked', count: dealerCount, setSelectedDealer: setSelectedDealer })));
        });
    };
    return (React.createElement(DealersListWrapper, { zeroResults: zeroResults },
        React.createElement(RenderedDealersList, { ref: dealerListRef, zeroResults: zeroResults }, renderDealersList())));
};
export default DealersListItems;
