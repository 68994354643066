import React, { useState, useEffect } from 'react';
import { FieldsetOneCol, FieldsetWrapper, GroupHeading, VisuallyHiddenLegend, MoreOptionsButtonsWrapper, } from '../styles/CheckboxFilter.styles';
import { CheckboxSimple } from './CheckboxSimple';
import { Text } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { CheckboxColorTile } from './CheckboxColorTile';
import InfoButton from './InfoButton';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { useServicesContext } from '../../../context';
import { TextLink } from '@oneaudi/unified-web-components';
export const CheckboxFilter = ({ isCarTypeIconCheckbox, isColorTypeCheckbox, filterCategory, group, layoutWidth, isPartOfEquipmentFilter, visibleIn, headingLevel = 'h4', isPlpQuickFilters, isExtColorTypeCheckbox, isIntColorTypeCheckbox, }) => {
    const { groupHeadline, groupText, items } = group;
    if (items.length < 1) {
        return null;
    }
    if (groupHeadline === 'ranges') {
        return null;
    }
    const filterGroupI18nLabel = useI18n({
        id: `nemo.sc.filter.filtergroups.${groupHeadline}`,
        defaultMessage: '',
    });
    const showMoreOptionsLabel = useI18n({
        id: `nemo.sc.plp.quickfilters.showmoreoptions`,
        defaultMessage: 'Show more options',
    });
    const hideOptionsLabel = useI18n({
        id: `nemo.sc.plp.quickfilters.hideoptions`,
        defaultMessage: 'Hide options',
    });
    const breakpoint = useBreakpoint();
    const [showAllCheckboxesInQuickFilters, setShowAllCheckboxesInQuickFilters] = useState(false);
    const [maxNumberOfCarTypeIconCheckboxesToDisplay, setMaxNumberOfCarTypeIconCheckboxesToDisplay] = useState(0);
    const [maxNumberOfInteriorColorTypeCheckboxesToDisplay, setMaxNumberOfInteriorColorTypeCheckboxesToDisplay,] = useState(0);
    const [maxNumberOfExteriorColorTypeCheckboxesToDisplay, setMaxNumberOfExteriorColorTypeCheckboxesToDisplay,] = useState(0);
    const [maxNumberOfCheckboxesToDisplay, setMaxNumberOfCheckboxesToDisplay] = useState(0);
    let maxNumberOfCheckboxes = 0;
    if (isCarTypeIconCheckbox) {
        maxNumberOfCheckboxes = maxNumberOfCarTypeIconCheckboxesToDisplay;
    }
    else if (isIntColorTypeCheckbox) {
        maxNumberOfCheckboxes = maxNumberOfInteriorColorTypeCheckboxesToDisplay;
    }
    else if (isExtColorTypeCheckbox) {
        maxNumberOfCheckboxes = maxNumberOfExteriorColorTypeCheckboxesToDisplay;
    }
    else {
        maxNumberOfCheckboxes = maxNumberOfCheckboxesToDisplay;
    }
    const numberOfAdditionalCheckboxes = items.length - maxNumberOfCheckboxes;
    const filterGroupLabel = filterGroupI18nLabel || groupText;
    useEffect(() => {
        switch (breakpoint) {
            case 'XS':
                setMaxNumberOfCarTypeIconCheckboxesToDisplay(2);
                setMaxNumberOfInteriorColorTypeCheckboxesToDisplay(4);
                setMaxNumberOfExteriorColorTypeCheckboxesToDisplay(8);
                setMaxNumberOfCheckboxesToDisplay(3);
                break;
            case 'S':
                setMaxNumberOfCarTypeIconCheckboxesToDisplay(2);
                setMaxNumberOfInteriorColorTypeCheckboxesToDisplay(5);
                setMaxNumberOfExteriorColorTypeCheckboxesToDisplay(10);
                setMaxNumberOfCheckboxesToDisplay(3);
                break;
            case 'M':
                setMaxNumberOfCarTypeIconCheckboxesToDisplay(4);
                setMaxNumberOfInteriorColorTypeCheckboxesToDisplay(4);
                setMaxNumberOfExteriorColorTypeCheckboxesToDisplay(8);
                setMaxNumberOfCheckboxesToDisplay(6);
                break;
            case 'L':
                setMaxNumberOfCarTypeIconCheckboxesToDisplay(2);
                setMaxNumberOfInteriorColorTypeCheckboxesToDisplay(3);
                setMaxNumberOfExteriorColorTypeCheckboxesToDisplay(6);
                setMaxNumberOfCheckboxesToDisplay(3);
                break;
            case 'XL':
                setMaxNumberOfCarTypeIconCheckboxesToDisplay(2);
                setMaxNumberOfInteriorColorTypeCheckboxesToDisplay(4);
                setMaxNumberOfExteriorColorTypeCheckboxesToDisplay(8);
                setMaxNumberOfCheckboxesToDisplay(3);
                break;
            case 'XXL':
                setMaxNumberOfCarTypeIconCheckboxesToDisplay(2);
                setMaxNumberOfInteriorColorTypeCheckboxesToDisplay(5);
                setMaxNumberOfExteriorColorTypeCheckboxesToDisplay(10);
                setMaxNumberOfCheckboxesToDisplay(3);
                break;
        }
    }, [breakpoint]);
    const handleShowMoreCheckboxes = () => {
        setShowAllCheckboxesInQuickFilters(true);
        trackShowMoreOptions(trackingService, showMoreOptionsLabel, filterCategory);
    };
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const handleShowFewerCheckboxes = () => {
        setShowAllCheckboxesInQuickFilters(false);
    };
    const getColorParameter = (colorDetails) => {
        const itemColor = colorDetails.slice(colorDetails.indexOf('.') + 1);
        if (colorDetails.includes('exterior')) {
            return { colorType: 'exterior', colorName: itemColor };
        }
        return { colorType: 'interior', colorName: itemColor };
    };
    return (React.createElement(FieldsetWrapper, { layoutWidth: layoutWidth, isColorTypeCheckbox: isColorTypeCheckbox, isPlpQuickFilters: isPlpQuickFilters },
        React.createElement(GroupHeading, { isPlpQuickFilters: isPlpQuickFilters },
            React.createElement(Text, { as: headingLevel, variant: isPlpQuickFilters ? 'copy2' : 'order3' }, filterGroupLabel),
            isColorTypeCheckbox && (React.createElement(InfoButton, { filterGroupIdOrFilterId: groupHeadline, filterOrGroupText: filterGroupLabel, filterCategory: filterCategory }))),
        React.createElement(FieldsetOneCol, { key: groupHeadline, "$layoutWidth": layoutWidth, "$isColorTypeCheckbox": isColorTypeCheckbox, "$isPartOfEquipmentFilter": isPartOfEquipmentFilter, "$isPlpQuickFilters": isPlpQuickFilters },
            React.createElement(VisuallyHiddenLegend, null, filterGroupLabel),
            items.map((item, index) => {
                if (isPlpQuickFilters) {
                    if (isColorTypeCheckbox &&
                        (index < maxNumberOfCheckboxes || showAllCheckboxesInQuickFilters)) {
                        const color = getColorParameter(item.itemHeadline);
                        return (React.createElement(CheckboxColorTile, { filterCategory: filterCategory, visibleIn: visibleIn, itemHeadline: item.itemHeadline, itemText: item.itemText, groupHeadline: groupHeadline, key: item.itemHeadline, color: color, isPlpQuickFilters: isPlpQuickFilters }));
                    }
                    if (index < maxNumberOfCheckboxes || showAllCheckboxesInQuickFilters) {
                        return (React.createElement(CheckboxSimple, { groupHeadline: groupHeadline, filterCategory: filterCategory, visibleIn: visibleIn, isCarTypeIconCheckbox: isCarTypeIconCheckbox, itemHeadline: item.itemHeadline, itemText: item.itemText, key: item.itemHeadline, isPlpQuickFilters: isPlpQuickFilters }));
                    }
                }
                else {
                    if (isColorTypeCheckbox) {
                        const color = getColorParameter(item.itemHeadline);
                        return (React.createElement(CheckboxColorTile, { filterCategory: filterCategory, visibleIn: visibleIn, itemHeadline: item.itemHeadline, itemText: item.itemText, groupHeadline: groupHeadline, key: item.itemHeadline, color: color, isPlpQuickFilters: isPlpQuickFilters }));
                    }
                    return (React.createElement(CheckboxSimple, { groupHeadline: groupHeadline, filterCategory: filterCategory, visibleIn: visibleIn, isCarTypeIconCheckbox: isCarTypeIconCheckbox, itemHeadline: item.itemHeadline, itemText: item.itemText, key: item.itemHeadline }));
                }
                return '';
            })),
        React.createElement(MoreOptionsButtonsWrapper, null,
            isPlpQuickFilters &&
                numberOfAdditionalCheckboxes > 0 &&
                !showAllCheckboxesInQuickFilters && (React.createElement(TextLink, { icon: "add", iconPosition: "leading", size: "sm", variant: "standalone", onClick: handleShowMoreCheckboxes }, showMoreOptionsLabel)),
            isPlpQuickFilters &&
                numberOfAdditionalCheckboxes > 0 &&
                showAllCheckboxesInQuickFilters && (React.createElement(TextLink, { icon: "remove", iconPosition: "leading", size: "sm", variant: "standalone", onClick: handleShowFewerCheckboxes }, hideOptionsLabel)))));
};
export const trackShowMoreOptions = (trackingService, label, value) => {
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: 'content',
                    eventName: 'vtp quick filter - show more',
                },
                attributes: {
                    componentName: 'vtp-quick-filter',
                    label,
                    targetURL: '',
                    clickID: '',
                    elementName: 'text link',
                    value,
                    pos: '',
                },
            },
        });
    }
};
export default CheckboxFilter;
