import React, { useMemo, useState } from 'react';
import { Button, audiLightTheme, ThemeProvider } from '@audi/audi-ui-react-v2';
import { SELECTORS } from '@oneaudi/stck-store';
import { useSelector } from 'react-redux';
import { useServicesContext } from '../../../context';
import { useScsUrlParts } from '../../../hooks';
import { Icon } from '../../../components';
import { favorite } from './Icons';
import { useI18n } from '@oneaudi/i18n-context';
import { MobileOptions, OptionsWrapper, DesktopOptions, OptionsToolbarContainer, StyledButton, StyledText, StyledDivider, MobileFavouritesHeading, DesktopFavouritesHeading, ButtonsWrapper, } from '../styles/OptionsToolbar.styles';
import { useClearAllFilters } from '../FilterContextHooks';
import { MandatoryAreaSearchState } from '../types';
export const OptionsToolbar = ({ mandatoryAreaSearchState = MandatoryAreaSearchState.DISABLED, openMandatoryAreaSearchModal = () => { }, }) => {
    const clearAllFilters = useClearAllFilters();
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    const { filterStartPagePathname, favouritesPagePathname } = audiContentService.getContent()
        .fields;
    const usedCarsHeading = useI18n({
        id: `nemo.sc.filter.toolbarUsedCarsHeading`,
        defaultMessage: 'Used cars',
    });
    const newCarsHeading = useI18n({
        id: `nemo.sc.filter.toolbarNewCarsHeading`,
        defaultMessage: 'New cars',
    });
    const favouritesPageHeading = useI18n({
        id: `nemo.sc.filter.toolbarFavouritesPageHeading`,
        defaultMessage: 'Your favourites',
    });
    const newSearchLabel = useI18n({
        id: `nemo.sc.filter.toolbarNewSearch`,
        defaultMessage: 'New search',
    });
    const favouritesLabel = useI18n({
        id: `nemo.sc.filter.toolbarFavourites`,
        defaultMessage: 'Favourites',
    });
    const optionsLabel = useI18n({
        id: `nemo.ui.sc.toolbar.options.text`,
        defaultMessage: 'Options',
    });
    const backButtonLabel = useI18n({
        id: `nemo.ui.sc.favorites.empty.back`,
        defaultMessage: 'Back',
    });
    const addLocationLabel = useI18n({
        id: `nemo.ui.sc.mas.toolbar.addLocation`,
        defaultMessage: 'Add location',
    });
    const changeLocationLabel = useI18n({
        id: `nemo.ui.sc.mas.toolbar.changeLocation`,
        defaultMessage: 'Change location',
    });
    const isOnFavouritesPage = typeof window !== 'undefined' &&
        window.location.pathname.toString().includes(favouritesPagePathname);
    const { isUsedCarMkt } = useScsUrlParts();
    const headingLabel = isUsedCarMkt ? usedCarsHeading : newCarsHeading;
    const [areMobileOptionsOpen, setAreMobileOptionsOpen] = useState(false);
    const mandatoryAreaSearchActive = useMemo(() => {
        return mandatoryAreaSearchState !== MandatoryAreaSearchState.DISABLED;
    }, [mandatoryAreaSearchState]);
    const amountOfFavouriteVehicles = useSelector((state) => SELECTORS.VEHICLES.getFavoriteVehicleIdsState(state));
    return (React.createElement(ThemeProvider, { theme: audiLightTheme },
        React.createElement(OptionsToolbarContainer, null,
            isOnFavouritesPage ? (React.createElement(React.Fragment, null,
                React.createElement(Button, { variant: "text", icon: "back", size: "medium", href: filterStartPagePathname }, backButtonLabel),
                React.createElement(MobileFavouritesHeading, null, favouritesPageHeading))) : (React.createElement(StyledText, null, headingLabel)),
            React.createElement(MobileOptions, { mandatoryAreaSearchActive: mandatoryAreaSearchActive },
                React.createElement(ButtonsWrapper, null,
                    React.createElement(Button, { variant: "text", icon: areMobileOptionsOpen ? 'cancel' : 'more', size: "medium", onClick: () => setAreMobileOptionsOpen((prevAreMobileOptionsOpen) => !prevAreMobileOptionsOpen) }, optionsLabel),
                    mandatoryAreaSearchActive && (React.createElement(Button, { variant: "text", icon: mandatoryAreaSearchState === MandatoryAreaSearchState.ENABLED
                            ? 'poi'
                            : 'poi-active', onClick: openMandatoryAreaSearchModal, spaceInlineStart: "l" }, mandatoryAreaSearchState === MandatoryAreaSearchState.ENABLED
                        ? addLocationLabel
                        : changeLocationLabel))),
                areMobileOptionsOpen && (React.createElement(OptionsWrapper, { mandatoryAreaSearchActive: mandatoryAreaSearchActive },
                    !isOnFavouritesPage && (React.createElement(React.Fragment, null,
                        React.createElement(StyledButton, { variant: "text", icon: "search", size: "small", onClick: () => {
                                setAreMobileOptionsOpen(false);
                                clearAllFilters();
                            }, mandatoryAreaSearchActive: mandatoryAreaSearchActive }, newSearchLabel),
                        React.createElement(StyledDivider, { mandatoryAreaSearchActive: mandatoryAreaSearchActive }),
                        React.createElement(StyledDivider, { mandatoryAreaSearchActive: mandatoryAreaSearchActive }))),
                    React.createElement(StyledButton, { variant: "text", icon: React.createElement(Icon, { icon: favorite }), href: favouritesPagePathname, size: "small", onClick: () => setAreMobileOptionsOpen(false), mandatoryAreaSearchActive: mandatoryAreaSearchActive },
                        `(${amountOfFavouriteVehicles.length})`,
                        " ",
                        favouritesLabel)))),
            React.createElement(DesktopOptions, { mandatoryAreaSearchActive: mandatoryAreaSearchActive },
                !isOnFavouritesPage && (React.createElement(Button, { variant: "text", icon: "search", size: "medium", spaceInlineEnd: "l", onClick: () => {
                        clearAllFilters();
                    } }, newSearchLabel)),
                React.createElement(Button, { variant: "text", icon: React.createElement(Icon, { icon: favorite }), href: favouritesPagePathname, size: "medium" },
                    `(${amountOfFavouriteVehicles.length})`,
                    " ",
                    favouritesLabel),
                mandatoryAreaSearchActive && (React.createElement(Button, { variant: "text", icon: mandatoryAreaSearchState === MandatoryAreaSearchState.ENABLED ? 'poi' : 'poi-active', onClick: openMandatoryAreaSearchModal, spaceInlineStart: "l" }, mandatoryAreaSearchState === MandatoryAreaSearchState.ENABLED
                    ? addLocationLabel
                    : changeLocationLabel)))),
        isOnFavouritesPage && (React.createElement(DesktopFavouritesHeading, null, favouritesPageHeading))));
};
export default OptionsToolbar;
