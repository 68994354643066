export const trackMASFeatureAppReady = (trackingService, appId) => {
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: 'feature_app_ready',
                    eventName: 'mandatory area search - feature app ready',
                },
                attributes: {
                    componentName: 'mandatory-area-search',
                    label: '',
                    targetURL: '',
                    clickID: '',
                    elementName: '',
                    value: '',
                    pos: '',
                },
            },
            componentUpdate: {
                attributes: {
                    implementer: 2,
                },
                componentInfo: {
                    componentID: appId,
                },
            },
        });
    }
};
export const trackClicks = (trackingService, eventName, label, elementName, value, pos, resetPageId) => {
    if (trackingService) {
        const trackingObject = {
            event: {
                eventInfo: {
                    eventAction: 'content',
                    eventName,
                },
                attributes: {
                    componentName: 'mandatory-area-search',
                    label,
                    targetURL: '',
                    clickID: '',
                    elementName,
                    value,
                    pos,
                },
            },
        };
        if (resetPageId) {
            trackingObject.componentUpdate = {
                page: {
                    pageInfo: {
                        pageID: '',
                    },
                },
            };
        }
        trackingService.track(trackingObject);
    }
};
export const trackViewChange = (trackingService, pageID) => {
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: 'view_change',
                    eventName: 'mandatory area search - layer',
                },
                attributes: {
                    componentName: 'mandatory-area-search',
                    label: '',
                    targetURL: '',
                    clickID: '',
                    elementName: '',
                    value: '',
                    pos: '',
                },
            },
            componentUpdate: {
                page: {
                    pageInfo: {
                        pageID,
                    },
                },
            },
        });
    }
};
