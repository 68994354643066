import React from 'react';
import { Map, FocusedDealerWrapper, StyledAddressIcon, StyledAddressTextFullWidth, StyledAddressTextNextToIcon, AddressMobile, } from '../styles/LocationFilter.styles';
import { Wrapper } from '@googlemaps/react-wrapper';
import { useServicesContext } from '../../../context';
import { useDesktopOrMobileView } from '../../../hooks';
import { DealersListHeader } from './DealersListHeader';
import { FilterOverlayAccordion } from './FilterOverlayAccordion';
import { FilterOverlayAccordionSection } from './FilterOverlayAccordionSection';
import { useI18n } from '@oneaudi/i18n-context';
import { getAllCountersForMatches } from '../utils/utils';
import { useFilterData } from '../FilterContextHooks';
import { ZeroResultsBanner } from './ZeroResultsBanner';
import { extractGoogleMapsAuthParams } from '../utils';
export const MapWrapper = ({ consent, dealersList, inputRef, isLoadingNewDealerData, mapRef, mobileNavBarState, renderMap, setSelectedDealer, }) => {
    const filterData = useFilterData();
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const country = localeService.countryCode;
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    const { locationFilterConfig_mapsAuthQueryParams } = audiContentService.getContent()
        .fields;
    const mapsAuthQueryParams = locationFilterConfig_mapsAuthQueryParams || '';
    const { googleMapsApiKey, googleMapsClient, googleMapsChannel } = extractGoogleMapsAuthParams(mapsAuthQueryParams);
    const isDesktop = useDesktopOrMobileView('1024px') === 'desktop';
    const isMobile = useDesktopOrMobileView('767px') === 'mobile';
    const isScreenSizeM = !isDesktop && !isMobile;
    const isMobileMapView = !isDesktop && mobileNavBarState === 'nav-bar-location-search-mobile--map-view';
    const ariaLabelForAccordionButton = useI18n({
        id: `nemo.sc.filter.locationfilter.ariaLabelForAccordionButton`,
        defaultMessage: 'Displays the address of dealer',
    });
    const [noMatchesFoundCounter, singleMatchFoundCounter, multipleMatchesFoundCounter] = getAllCountersForMatches();
    const getI18nCounterForThisNumberOfMatches = (numberOfMatches) => {
        const counterForThisNumberOfMatches = numberOfMatches === 0
            ? noMatchesFoundCounter
            : numberOfMatches === 1
                ? singleMatchFoundCounter
                : multipleMatchesFoundCounter;
        return counterForThisNumberOfMatches;
    };
    const focusedDealer = dealersList === null || dealersList === void 0 ? void 0 : dealersList.find((item) => {
        return item.status === 'focused';
    });
    const renderFocusedDealer = () => {
        var _a, _b;
        if (!focusedDealer)
            return null;
        const dealersFilterData = (_b = (_a = filterData === null || filterData === void 0 ? void 0 : filterData.groups) === null || _a === void 0 ? void 0 : _a.dealers) === null || _b === void 0 ? void 0 : _b.members;
        const dealerDataFromFilterEndpoint = dealersFilterData[`dealer.${focusedDealer.id}`] || {
            count: 0,
            active: false,
        };
        const dealerIsActive = dealerDataFromFilterEndpoint.active;
        const dealerCount = dealerDataFromFilterEndpoint.count;
        const i18nCounterForThisNumberOfMatches = ` ${getI18nCounterForThisNumberOfMatches(dealerCount)}`;
        const label = `${focusedDealer.name} (${dealerCount}${isScreenSizeM ? i18nCounterForThisNumberOfMatches : ''})`;
        const ariaLabelForAccordionButtonForThisDealer = `${ariaLabelForAccordionButton} ${focusedDealer.name}`;
        const dealersListAccordionItemProps = {
            isCheckedControlledByClickOnMarker: focusedDealer.status === 'focused' || focusedDealer.status === 'checked',
            active: dealerIsActive,
            dealerStatus: focusedDealer.status,
            setSelectedDealer,
            count: dealerCount,
            ariaLabelForAccordionButton: ariaLabelForAccordionButtonForThisDealer,
        };
        return (React.createElement(FilterOverlayAccordion, null,
            React.createElement(FilterOverlayAccordionSection, { key: `dealer-${focusedDealer.id}`, id: `dealer.${focusedDealer.id}`, label: label, isUsedInLocationFilter: true, dealerAccordionItemProps: dealersListAccordionItemProps, isDisplayingFocusedDealer: true, isDisplayingFocusedDealerBellowMap: true },
                React.createElement(AddressMobile, { status: focusedDealer.status },
                    React.createElement(StyledAddressIcon, { disabled: dealerCount === 0 }),
                    React.createElement(StyledAddressTextNextToIcon, { disabled: dealerCount === 0 },
                        focusedDealer.street,
                        ","),
                    React.createElement(StyledAddressTextFullWidth, { disabled: dealerCount === 0 },
                        focusedDealer.region,
                        " ",
                        focusedDealer.city)))));
    };
    return (React.createElement(React.Fragment, null,
        isMobileMapView && (React.createElement(React.Fragment, null,
            React.createElement(DealersListHeader, { dealersList: dealersList }))),
        consent && (React.createElement(Map, { visible: isMobileMapView || isDesktop, zeroResultsFound: (dealersList === null || dealersList === void 0 ? void 0 : dealersList.length) === 0 },
            React.createElement(Wrapper, { apiKey: googleMapsApiKey, client: googleMapsClient, channel: googleMapsChannel, render: renderMap, libraries: ['places'], region: country },
                React.createElement("div", { ref: mapRef, style: { width: '100%', height: '100%' } })),
            isMobileMapView && !isLoadingNewDealerData && (dealersList === null || dealersList === void 0 ? void 0 : dealersList.length) === 0 && (React.createElement(ZeroResultsBanner, { inputRef: inputRef, isDisplayedOnMapTab: true })),
            isMobileMapView && focusedDealer && (React.createElement(FocusedDealerWrapper, null, renderFocusedDealer()))))));
};
export default Map;
