import React, { useState, useCallback, useEffect, useMemo, createRef } from 'react';
import { FilterOverlayAccordionContainer } from '../styles/FilterOverlay.styles';
export const FilterOverlayAccordion = ({ isFilterOverlayOpened, children, overlayRef, multiple, isUsedInEquipmentFilter, mobileOrDesktop, className, }) => {
    const accordionSections = useMemo(() => children
        ? React.Children.map(children, (child) => child)
        : [], [children]);
    const stateMask = useCallback((stateFn) => {
        const firstIndex = accordionSections.findIndex((child) => stateFn(child.props));
        return accordionSections.map((child, index) => stateFn(child.props) && (multiple ? true : index === firstIndex));
    }, [accordionSections, multiple]);
    const defaultState = useMemo(() => stateMask((props) => Boolean(props.defaultOpen)), [stateMask]);
    const [accordionSectionState, setAccordionSectionState] = useState(defaultState);
    const controlledState = useMemo(() => accordionSections.some((child) => child.props.open !== undefined)
        ? stateMask((props) => {
            return Boolean(props.open);
        })
        : null, [accordionSections, stateMask]);
    useEffect(() => {
        if (controlledState)
            setAccordionSectionState(controlledState);
    }, [controlledState]);
    useEffect(() => {
        if (!isFilterOverlayOpened)
            setAccordionSectionState(defaultState);
    }, [isFilterOverlayOpened]);
    const sectionToggleRefs = accordionSections.map(() => createRef());
    const handleKeyDown = (refIndex) => (event) => {
        let nextFocusIndex = -1;
        switch (event.key) {
            case 'Home':
                nextFocusIndex = 0;
                break;
            case 'End':
                nextFocusIndex = sectionToggleRefs.length - 1;
                break;
            case 'ArrowUp':
                nextFocusIndex = refIndex === 0 ? sectionToggleRefs.length - 1 : refIndex - 1;
                break;
            case 'ArrowDown':
                nextFocusIndex = refIndex === sectionToggleRefs.length - 1 ? 0 : refIndex + 1;
                break;
            default:
                nextFocusIndex = -1;
        }
        if (nextFocusIndex !== -1) {
            event.preventDefault();
            const nextToggle = sectionToggleRefs[nextFocusIndex].current;
            if (nextToggle)
                nextToggle.focus();
        }
    };
    const handleToggle = (sectionIndex, callback = () => { }) => (willOpen) => {
        var _a;
        const nextState = multiple
            ? [...accordionSectionState]
            : accordionSectionState.map(() => false);
        if (nextState) {
            nextState[sectionIndex] = willOpen;
        }
        if (willOpen) {
            (_a = overlayRef === null || overlayRef === void 0 ? void 0 : overlayRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
        setAccordionSectionState(nextState);
        callback(willOpen);
    };
    let sectionIndex = 0;
    return (React.createElement(FilterOverlayAccordionContainer, { className: className },
        React.createElement(React.Fragment, null, accordionSections.map((child) => {
            const modified = React.cloneElement(child, {
                open: controlledState
                    ? controlledState[sectionIndex]
                    : accordionSectionState[sectionIndex],
                defaultOpen: defaultState[sectionIndex],
                onToggle: controlledState
                    ? child.props.onToggle
                    : handleToggle(sectionIndex, child.props.onToggle),
                onKeyDown: handleKeyDown(sectionIndex),
                toggleRef: sectionToggleRefs[sectionIndex],
                isUsedInEquipmentFilter,
                isUsedInEquipmentFilterOnDesktop: isUsedInEquipmentFilter && mobileOrDesktop === 'desktop',
            });
            sectionIndex++;
            return modified;
        }))));
};
