import React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { CookieConsentRequestContainer, CookieConsentRequestContent, } from '../styles/LocationFilter.styles';
import { Text, Button, Icon } from '@audi/audi-ui-react-v2';
import { trackClicks } from '../MAStracking';
import { useServicesContext } from '../../../context';
export const CookieConsentRequest = ({ mapConsentButtonText, openConsentOverlay, setIsTwoClickOverlayOpened, showIcon = true, isMas, }) => {
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const requestHeadline = useI18n({
        id: `nemo.sc.filter.locationfilter.cookieConsentRequestDealerList.Headline`,
        defaultMessage: 'Please give us your consent',
    });
    const requestContent = useI18n({
        id: `nemo.sc.filter.locationfilter.cookieConsentRequestDealerList.Content`,
        defaultMessage: 'Please note that some content may not be available for use without consent. We therefore recommend that you give the necessary consent.',
    });
    return (React.createElement(CookieConsentRequestContainer, null,
        showIcon && React.createElement(Icon, { name: "system-info", size: "small", variant: "primary", spaceStackStart: "xxs" }),
        React.createElement(CookieConsentRequestContent, { isMas: true },
            React.createElement(Text, { as: isMas ? 'h4' : 'h2', variant: "order3" }, requestHeadline),
            React.createElement(Text, { as: "p", variant: "copy1" }, requestContent),
            React.createElement(Button, { variant: "primary", size: "small", onClick: () => {
                    openConsentOverlay();
                    setIsTwoClickOverlayOpened(true);
                    if (isMas) {
                        trackClicks(trackingService, 'mandatory area search - consent layer open click', mapConsentButtonText, 'button', '', '');
                    }
                } }, mapConsentButtonText))));
};
export default CookieConsentRequest;
