import styled from 'styled-components';
import React from 'react';
import { Button, Select } from '@audi/audi-ui-react-v2';
export const MasOverlayContainer = styled.div `
  display: flex;
  flex-flow: column;
  }
`;
export const MasOverlayHeadlineWrapper = styled.div `
  width: 100%;
  padding-block-end: var(${(props) => props.theme.responsive.spacing.l});
  }
`;
export const ConsentRequestWrapper = styled.div `
  padding-block-end: var(${(props) => props.theme.responsive.spacing.l});
  }
`;
export const LocationInputWrapper = styled.div `
  width: 100%;
  display: grid;
  align-items: end;
  grid-template-columns: auto;
  padding-block-end: var(${(props) => props.theme.responsive.spacing.xxl});
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    grid-template-columns: 45% 50%;
    grid-column-gap: 5%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    height: 57px;
    grid-template-columns: 50% 50%;
    margin-top: unset;
    grid-column-gap: unset;
    padding-block-end: var(${(props) => props.theme.responsive.spacing.xs});
  }
`;
export const SearchWrapper = styled.div `
  display: grid;
  width: 100%;
  grid-template-columns: 95% auto;
`;
export const SearchStyle = styled.div `
  margin-inline-end: 12px;
`;
export const LocateMeButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  min-width: 24px;
  max-width: 36px;
  min-height: 24px;
  max-height: 36px;
  :disabled {
    box-shadow: none;
  }

  /* locate-me icon and delete text */
  svg {
    flex: 1 0 24px;
  }
`;
export const StyledSelect = styled((props) => React.createElement(Select, Object.assign({}, props))) `
  margin-top: var(${(props) => props.theme.responsive.spacing.l});
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-top: unset;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-inline-start: 24px;
  }
`;
export const StyledResultsButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  width: auto;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    width: fit-content;
  }
  display: flex;
  justify-content: center;
  :disabled {
    box-shadow: none;
  }
`;
export const CookieConsentWrapper = styled.div `
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: var(${(props) => props.theme.responsive.spacing.m});
  margin-top: var(${(props) => props.theme.responsive.spacing.m});
  margin-bottom: var(${(props) => props.theme.responsive.spacing.l});
  gap: var(${(props) => props.theme.responsive.spacing.s});
  color: var(${(props) => props.theme.colors.ui.primary});
  background-color: var(${(props) => props.theme.colors.ui.highlight.tertiary});
  height: fit-content;
  box-sizing: border-box;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-top: 0px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-top: 16px;
  }

  svg {
    flex: 1 0 24px;
  }
`;
export const CloseButtonWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: var(--colors-background-level-1-611444a);
  z-index: 100;
`;
export const StyledCloseButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  position: fixed;
  right: 0;
  top: 0;
`;
export const autocompleteCSS = `
    .pac-container {
      background-color: #ffffff;
      padding-block: 24px 24px;
      padding-inline: 16px 16px;
      z-index: 10000;
    }
    .pac-icon {
      display: none;
    }
   .pac-item,.pac-item-query, .pac-matched {
      color: '#333333';
      font-size: 16px;
      font-family: AudiType,sans-serif;
      font-stretch: 105%;
      line-height: 24px;
      font-weight: 400;
      border-top: none;
    }
    .pac-item{
      padding: 0px;
    }
     .pac-item:not(:last-child) {
      border-block-end: 1px solid #d9d9d9;
      padding-block-end: 10px; 
    }
    .pac-item:not(:first-child) {
      padding-block-start: 10px; 
    }
    .pac-item:hover, .pac-item-selected, .pac-item-selected:hover{
      background-color: '#f2f2f2';
      padding-inline-start: 8px;
      cursor: pointer;
    }
    .pac-item:hover span, .pac-item-selected:hover span, .pac-item-selected span{
      color: '#000000';
    }
    .marker-with-counter{
      margin-top: -6px;
    }
  `;
